import { apiRequest } from './api';

export const verifyUserPincode = async (pincode) => {
	try {
		await apiRequest('/user/pincode/verify', 'POST', { pincode });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const setUserPincode = async (pincode) => {
	try {
		await apiRequest('/user/pincode/set', 'POST', { pincode });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
