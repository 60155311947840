import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SumsubWebSdk from '@sumsub/websdk-react';

import Loader from '../utils/loader';

import { getSumsubKYCTokenAPI, getUserKYCStatusAPI } from '../../services/kyc';
import { Header } from '../../components/navigation/header';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { logout } from '../../services/auth';

export default function KYCProcess() {
	const { t } = useTranslation();

	const [kycToken, setKycToken] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const getAndUpdateKycStatus = async () => {
		const kycValue = localStorage.getItem('kyc');

		const response = await getUserKYCStatusAPI();

		if (!response.isSuccess) return;

		if (response.data !== kycValue) {
			localStorage.setItem('kyc', response.data);
			window.location.reload();
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const response = await getSumsubKYCTokenAPI();

			if (!response.isSuccess) return;

			setKycToken(response.token);

			setIsLoading(false);
		};

		fetchData();

		const interval = setInterval(() => {
			getAndUpdateKycStatus();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const handleSumSubKYCResponse = (response) => {
		// console.log('SumSub KYC Response ====> ', response);
	};

	const handleSumSubKYCError = (error) => {
		// console.log('SumSub KYC Error ====> ', error);
	};

	return (
		<div>
			<div className="p-5">
				<Header title={t('pages.kyc.title')} showLanguage={false} />
				<div>
					<div className="flex justify-end mt-[10px]">
						<PrimaryButton text={t('buttons.logout')} onClick={() => logout()} className="!w-[80px]" textColor="text-white" />
					</div>
					{isLoading ? (
						<Loader />
					) : (
						<div className="flex justify-center">
							<SumsubWebSdk
								accessToken={kycToken}
								expirationHandler={() => {
									// console.log(`SUMSUB Expired`);
								}}
								config={{ lang: 'en' }}
								//   options={options}
								onMessage={(message) => handleSumSubKYCResponse(message)}
								onError={(error) => handleSumSubKYCError(error)}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
