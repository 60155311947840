export const convertDateAndTimeToString = (date) => {
	const dateObject = new Date(date);

	// Format the date using toLocaleString() with options for Month, Day, and Year
	const formattedDate = dateObject.toLocaleString('en-US', {
		month: 'long', // Full month name (e.g., "August")
		day: 'numeric', // Day of the month (e.g., "17")
		year: 'numeric', // Full year (e.g., "2023")
		hour: 'numeric',
		minute: '2-digit',
		// hour12: false,
	});

	return formattedDate;
};

export const convertDateToString = (date) => {
	const dateObject = new Date(date);

	// Format the date using toLocaleString() with options for Month, Day, and Year
	const formattedDate = dateObject.toLocaleString('en-US', {
		month: 'long', // Full month name (e.g., "August")
		day: 'numeric', // Day of the month (e.g., "17")
		year: 'numeric', // Full year (e.g., "2023")
		// hour12: false,
	});

	return formattedDate;
};
