import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAccountsAPI } from '../services/accounts.js';

// Async thunk to fetch user accounts
export const fetchUserAccounts = createAsyncThunk('accounts/fetchUserAccounts', async () => {
	const response = await getUserAccountsAPI();
	if (!response.isSuccess) throw new Error(response.error);

	return response;
});

const accountsSlice = createSlice({
	name: 'accounts',
	initialState: {
		userWallets: [],
		totalBalance: 0,
		loading: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserAccounts.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchUserAccounts.fulfilled, (state, action) => {
				state.userWallets = action.payload.data;
				state.totalBalance = action.payload.totalBalance;
				state.loading = false;
			})
			.addCase(fetchUserAccounts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export default accountsSlice.reducer;
