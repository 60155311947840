import { jwtDecode } from 'jwt-decode';
import { getStorageToken, isTokenExpired } from '../utils/functions/token';
import { logout } from './auth';

const API_URL = process.env.REACT_APP_API_URL;

const handleResponse = async (response) => {
	const jsonResult = await response.json();

	if (!response.ok) throw new Error(jsonResult.error || 'Something went wrong, please try again or contact support.');

	return jsonResult;
};

const getHeaders = (authRequired = true) => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authRequired) {
		const token = getStorageToken();

		if (token) headers['Authorization'] = `Bearer ${token}`;
	}
	return headers;
};

export const apiRequest = async (endpoint, method, data = null, authRequired = true) => {
	if (authRequired) {
		const token = getStorageToken();
		const decodedToken = jwtDecode(token);
		const isTokenExpiredResponse = isTokenExpired(decodedToken);

		if (isTokenExpiredResponse) {
			logout();
		}
	}

	const response = await fetch(`${API_URL}${endpoint}`, {
		method,
		headers: getHeaders(authRequired),
		body: data ? JSON.stringify(data) : null,
	});

	return handleResponse(response);
};
