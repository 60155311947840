import { useEffect, useState, useCallback } from 'react';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';

import { PrimaryButton } from '../../../components/buttons/primary-button';
import { Input } from '../../../components/inputs/textInput';
import SelectInput from '../../../components/inputs/selectInput';
import { PageLogo } from '../../../components/utils/pageLogo';
import { getFeeByType } from '../../../services/fees';
import useValidation from '../../../hooks/useValidation';
import { IbanDataWithdrawSchema } from '../../../utils/validations/withdraw/withdraw-schema';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { getDetailsByIbanAPI, withdrawalAPI } from '../../../services/ibans';
import { useLocation } from 'react-router-dom';
import Loader from '../../utils/loader';
import { swapCurrencyAPI } from '../../../services/swap';
import { CustomModal } from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';

const regularCurrencies = [
	{ label: '🇷🇴 RON - NOVO LEU', value: 'RON' },
	{ label: '🇵🇱 PLN - POLAND ZLOTY', value: 'PLN' },
	{ label: '🇿🇦 ZAR - RAND (SOUTH AFRICA)', value: 'ZAR' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇸🇪 SEK - SWEDISH KRONA', value: 'SEK' },
	{ label: '🇨🇭 CHF - SWISS FRANCS', value: 'CHF' },
	{ label: '🇹🇳 TND - TUNISIAN DINAR', value: 'TND' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
	{ label: '🇹🇷 TRY - YENI TURKISH LIRA', value: 'TRY' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI DA CHINA', value: 'CNY' },
	{ label: '🇩🇿 DZD - ALGERIAN DINAR', value: 'DZD' },
	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇹🇭 THB - BAHT TAILANDES', value: 'THB' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇮🇸 ISK - COROA DA ISLÂNDIA', value: 'ISK' },
	{ label: '🇨🇿 CZK - CZECH KORUNA', value: 'CZK' },
	{ label: '🇩🇰 DKK - DANISH KRONE', value: 'DKK' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇭🇺 HUF - HUNGARIAN FORINT', value: 'HUF' },
	{ label: '🇮🇱 ILS - ISRAELI NEW SHEKEL', value: 'ILS' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇴 MOP - MACAU PATACA', value: 'MOP' },
	{ label: '🇲🇦 MAD - MAROCCAN DIRHAM', value: 'MAD' },
	{ label: '🇲🇽 MXN - MEXICAN PESO', value: 'MXN' },
	{ label: '🇳🇿 NZD - NEW ZEALAND DOLLAR', value: 'NZD' },
	{ label: '🇳🇴 NOK - NORWEGIAN KRONE', value: 'NOK' },
];

const instantCurrencies = [
	{ label: '🇰🇭 KHR - CAMBODIAN RIEL', value: 'KHR' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI (CHINA)', value: 'CNY' },
	{ label: '🇧🇩 BDT - BANGLADESHI TAKKA', value: 'BDT' },
	{ label: '🇮🇳 INR - INDIAN RUPEE', value: 'INR' },
	{ label: '🇮🇩 IDR - INDONESIAN RUPIAH', value: 'IDR' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇾 MYR - MALAYSIAN RINGGIT', value: 'MYR' },
	{ label: '🇵🇭 PHP - PHILIPPINE PESO', value: 'PHP' },
	{ label: '🇰🇷 KRW - SOUTH KOREAN WON', value: 'KRW' },
	{ label: '🇹🇼 TWD - TAIWANESE DOLLAR', value: 'TWD' },
	{ label: '🇹🇭 THB - THAI BAHT', value: 'THB' },
	{ label: '🇻🇳 VND - VIETNAMESE DONG', value: 'VND' },

	{ label: '🇧🇼 BWP - BOTSWANAN PULA', value: 'BWP' },
	{ label: '🇨🇫 XAF - CENTRAL AFRICAN CFA FRANC', value: 'XAF' },
	{ label: '🇨🇩 CDF - CONGOLESE FRANC (DRC)', value: 'CDF' },
	{ label: '🇬🇭 GHS - GHANAIAN CEDI', value: 'GHS' },
	{ label: '🇰🇪 KES - KENYAN SHILLING', value: 'KES' },
	{ label: '🇲🇼 MWK - MALAWIAN KWACHA', value: 'MWK' },
	{ label: '🇳🇬 NGN - NIGERIAN NAIRA', value: 'NGN' },
	{ label: '🇷🇼 RWF - RWANDAN FRANC', value: 'RWF' },
	{ label: '🇿🇦 ZAR - SOUTH AFRICAN RAND', value: 'ZAR' },
	{ label: '🇦🇪 AED - UNITED ARAB EMIRATES DIRHAM', value: 'AED' },
	{ label: '🇺🇬 UGX - UGANDAN SHILLING', value: 'UGX' },
	{ label: '🇫🇷 XOF - WEST AFRICAN CFA FRANC', value: 'XOF' },
	{ label: '🇿🇲 ZMW - ZAMBIAN KWACHA', value: 'ZMW' },

	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
];

export default function Withdrawal() {
	const location = useLocation();
	const { type = 'iban' } = location.state || {};

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const wireMethods = [
		{ label: t('pages.withdrawal.wire.method.regular'), value: 'regular' },
		{ label: t('pages.withdrawal.wire.method.instant'), value: 'instant' },
	];

	const { validationErrMsg, validate } = useValidation(IbanDataWithdrawSchema(t));

	const [wireType, setWireType] = useState('SEPA');
	// const [wireCurrency, setWireCurrency] = useState('EUR');

	const [wireMethod, setWireMethod] = useState(wireMethods[0].value);

	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState('EUR');
	const [reason, setReason] = useState('');

	const [step, setStep] = useState(0);

	const [totalFee, setTotalFee] = useState(null);
	const [pTotalFee, setPtotalFee] = useState(null);

	const [isVerified, setIsVerified] = useState(true);

	const [exchangedAmount, setExchangedAmount] = useState(0);

	const [methodFee, setMethodFee] = useState(0);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [bankDetails, setBankDetails] = useState({
		wireType,
		receiverFullName: '',
		receiverIBAN: '',
		receiverStreetAndNumber: '',
		receiverPostalCode: '',
		receiverCity: '',
		receiverBIC: '',
	});

	const getDetailsByIban = async () => {
		if (!bankDetails.receiverIBAN) return setErrMsg(t('errors.validations.iban'));

		const response = await getDetailsByIbanAPI(bankDetails.receiverIBAN);

		if (!response.isSuccess) {
			setBankDetails((data) => ({
				...data,
				receiverStreetAndNumber: '',
				receiverPostalCode: '',
				receiverCity: '',
			}));

			setIsVerified(false);

			return setErrMsg(response.error);
		}

		setErrMsg('');

		setBankDetails((data) => ({
			...data,
			receiverStreetAndNumber: response.data.address,
			receiverPostalCode: response.data.postalCode,
			receiverCity: response.data.city,
			receiverBIC: data.receiverBIC !== '' ? data.receiverBIC : response.data.bic,
		}));

		setIsVerified(true);
	};

	const handleWireTypeChange = (event) => {
		setWireType(event.target.value);
		// setWireCurrency(event.target.value === 'INSTANT' ? 'Other' : 'EUR');
		getFees(event.target.value, 'EUR');
	};

	// const handleCurrencyChange = (event) => {
	// 	setWireCurrency(event.target.value);
	// 	getFees(wireType, event.target.value);
	// };

	const getFees = useCallback(
		async (type = wireType) => {
			// const typeFilter =
			// 	type === 'SWIFT' && currency === 'Other'
			// 		? 'iban_withdraw_other_swift'
			// 		: type === 'SWIFT'
			// 		? 'iban_withdraw_eur_swift'
			// 		: type === 'SEPA'
			// 		? 'iban_withdraw_eur_sepa'
			// 		: type === 'INSTANT'
			// 		? 'iban_withdraw_other_instant'
			// 		: '';
			const typeFilter =
				type === 'SWIFT' ? 'iban_withdraw_swift' : type === 'SEPA' ? 'iban_withdraw_sepa' : type === 'INSTANT' ? 'iban_withdraw_instant' : null;
			const feesResponse = await getFeeByType({ type: typeFilter });

			if (feesResponse.isSuccess) {
				feesResponse.data.forEach((fee) => {
					if (fee.kind === 'percentage') setPtotalFee(fee.cost);
					else setTotalFee(fee.cost);
				});
			}
		},
		[wireType]
	);

	const getMethodFee = useCallback(async (selectedMethod = 'regular') => {
		const type = selectedMethod === 'regular' ? 'iban_withdraw_regular' : 'iban_withdraw_instant';

		const feesResponse = await getFeeByType({ type });

		if (feesResponse.isSuccess) {
			setMethodFee(feesResponse.data[0].cost);
		}
	}, []);

	const getRates = async (amount) => {
		if (amount < 100) return setExchangedAmount(0);

		if (!isLoading) {
			const response = await swapCurrencyAPI({ fromCurrency: currency, toCurrency: 'EUR', amount, withfee: false });

			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}
			setExchangedAmount(response.data);
		}
	};

	const handleBankWire = async () => {
		const data = {
			bankDetails,
			amount,
			currency,
			reason,
			fromMethod: type,
			wireType,
			wireMethod,
		};

		if (!amount || amount <= 0) return setErrMsg(t('errors.validations.invalidAmount'));

		if (!reason) return setErrMsg(t('errors.validations.reason'));

		if (wireType !== 'SEPA' && (!exchangedAmount || exchangedAmount <= 0)) return setErrMsg(t('errors.validations.chargedAmount'));

		const exchangedAmountWithFees = Math.round(parseFloat(exchangedAmount) + getAmountWithFees());
		console.log(exchangedAmountWithFees, ' the data');
		if (wireType === 'INSTANT' && exchangedAmountWithFees < 1200) return setErrMsg(t('errors.validations.withdrawExchangedAmount'));

		if (!isLoading) {
			setIsLoading(true);
			const response = await withdrawalAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}
		}

		setIsLoading(false);

		setIsSuccessModalOpen(true);
	};

	const getAmountWithFees = () => {
		const chargedAmount = wireType === 'SEPA' ? amount : exchangedAmount;
		const fee = chargedAmount * (pTotalFee / 100) > totalFee ? chargedAmount * (pTotalFee / 100) : totalFee;
		return fee;
	};

	useEffect(() => {
		getMethodFee();
		getFees();
	}, [getFees, getMethodFee]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			{step === 0 && (
				<>
					<div dir={langDir}>
						<Header title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')} />
						<AccountDetailsSubHeader />
						<h1 className="text-xl font-bold mb-[10px]">{t('pages.withdrawal.step1.title')}</h1>
						<div className="flex flex-col mb-5">
							<h2 className="text-lg  mb-2">{t('pages.withdrawal.step1.label.wireType')}</h2>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SEPA" checked={wireType === 'SEPA'} onChange={handleWireTypeChange} className="me-1" />
								<span>SEPA</span>
							</label>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SWIFT" checked={wireType === 'SWIFT'} onChange={handleWireTypeChange} className="me-1" />
								<span>SWIFT</span>
							</label>
							<label className="space-x-2">
								<input
									type="radio"
									name="wireType"
									value="INSTANT"
									checked={wireType === 'INSTANT'}
									onChange={handleWireTypeChange}
									className="me-1"
								/>
								<span>INSTANT</span>
							</label>
						</div>

						{/* <div className="flex flex-col mb-5">
							<h2 className="text-lg  mb-2">{t('pages.withdrawal.step1.label.currency')}</h2>
							<label className="space-x-2">
								<input
									type="radio"
									name="currency"
									value="EUR"
									checked={wireCurrency === 'EUR'}
									onChange={handleCurrencyChange}
									className="me-1"
									disabled={wireType === 'INSTANT'}
								/>
								<span>EUR</span>
							</label>
							<label className="space-x-2">
								<input
									type="radio"
									name="currency"
									disabled={wireType === 'SEPA'}
									value="Other"
									checked={wireCurrency === 'Other'}
									onChange={handleCurrencyChange}
									className="me-1"
								/>
								<span>Other</span>
							</label>
						</div> */}
						{wireType !== 'SEPA' && <p className="text-md mt-2 mb-2">{t('pages.withdrawal.step3.label.warning')}</p>}
						<div className="text-lg">
							<p className="font-bold">{t('pages.withdrawal.step1.label.fees')}</p>
							<p className="">
								{pTotalFee}% ({t('pages.withdrawal.step1.label.min')} €{totalFee})
							</p>
						</div>
					</div>

					<PageLogo />
					<PrimaryButton
						text={t('buttons.next')}
						className="mb-[10px]"
						textColor="text-white"
						onClick={() => {
							setStep(step + 1);
							setCurrency(wireType === 'SEPA' ? 'EUR' : wireType === 'INSTANT' ? instantCurrencies[0].value : regularCurrencies[0].value);
						}}
					/>
				</>
			)}

			{step === 1 && (
				<>
					<div dir={langDir}>
						<Header
							showLanguage={false}
							title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')}
							backNavigation={step === 0 ? null : () => setStep(step - 1)}
						/>
						<AccountDetailsSubHeader />

						<h1 className="text-lg mb-[10px]">{t('pages.withdrawal.step2.title')}</h1>

						<p>{t('pages.withdrawal.step2.label.fullName')}</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverFullName: value }))}
							placeholder="Ex. John Doe"
							type="text"
							value={bankDetails.receiverFullName}
						/>

						<div>
							<p>{t('pages.withdrawal.step2.label.bic')}</p>
							<Input
								onChange={(value) => setBankDetails((data) => ({ ...data, receiverBIC: value }))}
								placeholder="Ex. BZENLT22XXX"
								type="text"
								value={bankDetails.receiverBIC}
							/>
						</div>

						<p>{t('pages.withdrawal.step2.label.iban')}</p>
						<Input
							onChange={(value) => {
								// setIsVerified(false);
								setBankDetails((data) => ({ ...data, receiverIBAN: value }));
							}}
							placeholder="Ex. PL12345678910"
							type="text"
							value={bankDetails.receiverIBAN}
						/>
						{isVerified && (
							<div>
								<p>{t('pages.withdrawal.step2.label.streetAndNumber')}</p>
								<Input
									onChange={(value) => setBankDetails((data) => ({ ...data, receiverStreetAndNumber: value }))}
									placeholder="Ex. Promienna 10"
									type="text"
									value={bankDetails.receiverStreetAndNumber}
								/>
								<p>{t('pages.withdrawal.step2.label.postalCode')}</p>
								<Input
									onChange={(value) => setBankDetails((data) => ({ ...data, receiverPostalCode: value }))}
									placeholder="Ex. 20-203"
									type="text"
									value={bankDetails.receiverPostalCode}
								/>
								<p>{t('pages.withdrawal.step2.label.city')}</p>
								<Input
									onChange={(value) => setBankDetails((data) => ({ ...data, receiverCity: value }))}
									placeholder="Ex. Lublin"
									type="text"
									value={bankDetails.receiverCity}
								/>
							</div>
						)}
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
						{errMsg && <ErrorBubble error={errMsg} />}
						{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
						<PrimaryButton
							text={isVerified ? t('buttons.next') : t('buttons.verifyIban')}
							className="mb-[10px]"
							textColor="text-white"
							onClick={async () => {
								if (!isVerified) await getDetailsByIban();
								else {
									const isValid = validate({ ...bankDetails, wireType });
									if (!isValid) return;
									setStep(step + 1);
								}
							}}
						/>
					</div>
				</>
			)}

			{step === 2 && (
				<>
					<div dir={langDir}>
						<Header
							showLanguage={false}
							title={type === 'paytora' ? t('pages.withdrawal.header.paytora') : t('pages.withdrawal.header.iban')}
							backNavigation={
								step === 0
									? null
									: () => {
											setStep(step - 1);
											setAmount(0);
											setExchangedAmount(0);
											setReason('');
											setWireMethod('regular');
											setCurrency(regularCurrencies[0].value);
									  }
							}
						/>

						<AccountDetailsSubHeader />

						{wireType !== 'SEPA' ? (
							<>
								{/* <p>{t('pages.withdrawal.step3.label.method')}</p> */}
								{/* {
									<SelectInput
									defaultValue={{ value: wireMethods[0].value, label: wireMethods[0].label }}
									onChange={(value) => {
										getMethodFee(value);
										setWireMethod(value);
										setCurrency(value === 'instant' ? instantCurrencies[0].value : regularCurrencies[0].value);
										setAmount(0);
										setExchangedAmount(0);
									}}
									options={wireMethods.map((opt) => ({
										value: opt.value,
										label: opt.label,
									}))}
								/>
								} */}
								{/* <p className="text-md mt-2 mb-2">{t('pages.withdrawal.step3.label.warning')}</p> */}
								<p>{t('pages.withdrawal.step3.label.currency')}</p>
								{wireType === 'INSTANT' && (
									<SelectInput
										defaultValue={{
											value: instantCurrencies[0].value,
											label: instantCurrencies[0].label,
										}}
										onChange={(value) => {
											setCurrency(value);
											setAmount(0);
											setExchangedAmount(0);
										}}
										options={instantCurrencies.map((opt) => ({
											value: opt.value,
											label: opt.label,
										}))}
									/>
								)}
								{wireType === 'SWIFT' && (
									<SelectInput
										defaultValue={{
											value: regularCurrencies[0].value,
											label: regularCurrencies[0].label,
										}}
										onChange={(value) => {
											setCurrency(value);
											setAmount(0);
											setExchangedAmount(0);
										}}
										options={regularCurrencies.map((opt) => ({
											value: opt.value,
											label: opt.label,
										}))}
									/>
								)}
								<p className="text-red">
									{wireType === 'INSTANT' ? t('pages.withdrawal.wire.method.instant') : t('pages.withdrawal.wire.method.regular')}
								</p>
							</>
						) : (
							<>
								<p>{t('pages.withdrawal.step3.label.currency')}</p>
								<Input disabled={true} value="EUR" />
							</>
						)}
						<p>{t('pages.withdrawal.step3.label.amount')}</p>
						<Input
							placeholder="0.00"
							type="number"
							value={amount}
							onChange={(value) => {
								setAmount(value);
								if (wireType !== 'EUR') {
									getRates(value);
								}
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<p>{t('pages.withdrawal.step3.label.reason')}</p>
						<Input
							onChange={(value) => {
								setReason(value);
							}}
							placeholder="Ex. Food"
							type="text"
							value={reason}
						/>
						{wireType !== 'SEPA' && (
							<div>
								<div>
									{t('pages.withdrawal.step3.label.chargedAmount')}
									<Input
										disabled={true}
										value={'€' + (exchangedAmount && exchangedAmount > 0 ? Math.round(parseFloat(exchangedAmount) + getAmountWithFees()) : 0)}
									/>
									<p className="text-sm"> {t('pages.withdrawal.step3.label.minimum.chargedAmount')}</p>
								</div>

								{/* <p className="text-red text-md">
									{t('pages.withdrawal.step3.label.fees.first')} FX + €{methodFee} {t('pages.withdrawal.step3.label.fees.second')}
								</p> */}
								<p className="text-md mt-10 mb-2">{t('pages.withdrawal.step3.label.fees.warning')}</p>
							</div>
						)}
						{wireType === 'SEPA' && (
							<div>
								{t('pages.withdrawal.step3.label.chargedAmount')}
								<Input disabled={true} value={'€' + (amount && amount > 0 ? Math.round(parseFloat(amount) + getAmountWithFees()) : 0)} />
							</div>
						)}
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						<PrimaryButton
							text={t('buttons.send')}
							className="mb-[10px]"
							iconPath="icons/arrow_out_white.svg"
							textColor="text-white"
							onClick={handleBankWire}
						/>
					</div>
				</>
			)}

			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={t('modals.withdrawal.title')} //'Your withdraw action has been submitted'
				subTitle={t('modals.withdrawal.subTitle')} //You will receive a notification while process completed
				children={
					<div>
						<p className="pb-5 text-red font-bold">{t('modals.withdrawal.message')} </p>
						<div className="text-center">
							<PrimaryButton
								onClick={async () => {
									setIsSuccessModalOpen(false);
									window.location.reload();
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
