import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { getDecodedToken } from '../../utils/functions/token';

export default function IntercomNonUserChat({ logoBlack = true }) {
	// Fetch the decoded token
	const decodedToken = getDecodedToken();

	const langDir = localStorage.getItem('languageDir');

	useEffect(() => {
		// Initialize Intercom with custom launcher
		Intercom({
			app_id: 'jbfiwclz',
			custom_launcher_selector: '#intercom-custom-launcher',
			hide_default_launcher: true,
			language_override: localStorage.getItem('language'),
		});
	}, [decodedToken]);

	return (
		<div
			id="intercom-custom-launcher"
			className={`intercom-custom-launcher ${langDir === 'rtl' ? 'left-0 !me-[18px]' : 'right-0 !me-[60px]'} !mt-[4px]`}
		>
			<img src={logoBlack ? '/assets/icons/chat.svg' : '/assets/icons/chat_white.svg'} />
		</div>
	);
}
