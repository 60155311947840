import { apiRequest } from './api';

export async function cardToCardVerify(data) {
	try {
		const response = await apiRequest('/user/card-to-card/verify', 'POST', data);

		return { isSuccess: true, data: response.receiverCardId };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function cardToCardTransfer(data) {
	try {
		await apiRequest(`/user/card-to-card/transfer`, 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
