import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import numeral from 'numeral';

import { Input } from '../inputs/textInput';
import SelectInput from '../inputs/selectInput';

import { PrimaryButton } from '../buttons/primary-button';
import { GradientButton } from '../buttons/gradient_button';
import { SecondaryButton } from '../buttons/secondary-button';

import { CustomModal } from '../modals/customModal';

import { ErrorBubble } from '../utils/errorBubble';
import { CountriesList } from '../../utils/constants/countries-list';
import { physicalCardApplySchema } from '../../utils/validations/user-schema';

import Loader from '../../pages/utils/loader';

import { cardApplyAPI } from '../../services/cards';
import { getFeeByType } from '../../services/fees';

import useValidation from '../../hooks/useValidation';

export const TemplateCardItem = ({ template }) => {
	const { t } = useTranslation();

	const { validationErrMsg, validate } = useValidation(physicalCardApplySchema);

	const [kind, setKind] = useState(template.kind);

	const [shippingFee, setShippingFee] = useState(0);

	const [isVirtualApplyModalOpen, setIsVirtualAppyModalOpen] = useState(false);
	const [isPhysicalApplyModalOpen, setIsPhysicalApplyModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [selectedFromMethod, setSelectedFromMethod] = useState('paytora'); // paytora or iban

	const [errMsg, setErrMsg] = useState('');

	const [conditions, setConditions] = useState({
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const shippingMethodOptions = [
		{ value: 'basic', label: t('modals.cards.apply.physical.shipping.method.basic') },
		{ value: 'standard', label: t('modals.cards.apply.physical.shipping.method.standard') },
		{ value: 'fast', label: t('modals.cards.apply.physical.shipping.method.fast') },
	];

	const [shippingData, setShippingData] = useState({
		country: CountriesList[0],
		city: '',
		street: '',
		number: '',
		postCode: '',
		method: shippingMethodOptions[0].value,
		// pin: '',
	});

	const handleCheckboxChange = (fieldName) => {
		setConditions((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	const [cardDesign, setCardDesign] = useState({
		bgColor: 'bg-lightBlack',
		borderColor: 'border-gold',
		balanceTextColor: 'text-white',
		limitTextColor: 'text-darkGold',
		cardDetailsTextColor: 'text-white',
		logoPath: 'card_logo_gold.svg',
	});

	const getCardDesignByKind = () => {
		if (kind === 'premium') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-brown',
				borderColor: 'border-darkBrown',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}
		if (kind === 'kids_blue') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-darkBlue',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}

		if (kind === 'kids_silver') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-silver',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}
	};

	const apply = async () => {
		if (!conditions.agreeToPrivacyPolicy || !conditions.agreeToTerms) return setErrMsg(t('errors.validations.termsAndPrivacy'));

		const isApplyingCard = localStorage.getItem('applyCard');

		let shipping = null;
		if (template.type === 'physical') {
			const isValid = validate(shippingData);
			if (!isValid) return setErrMsg('');
			shipping = shippingData;
		}

		if (isApplyingCard === 'true') return window.location.reload();

		localStorage.setItem('applyCard', true);

		if (!isLoading) {
			setIsLoading(true);
			const response = await cardApplyAPI(template._id, { shippingData: shipping, paymentMethod: selectedFromMethod });

			localStorage.setItem('applyCard', false);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error);
			}

			template.type === 'virtual' && setIsVirtualAppyModalOpen(false);
			template.type === 'physical' && setIsPhysicalApplyModalOpen(false);

			setIsLoading(false);
			setIsSuccessModalOpen(true);
		}
	};

	const getShippingFees = async () => {
		const feesResponse = await getFeeByType({ type: 'shipping' });

		if (feesResponse.isSuccess) setShippingFee(feesResponse.data[0].shippingCost[shippingData.method]);
	};

	useEffect(() => {
		getCardDesignByKind();
		if (template.type === 'physical') getShippingFees();
		if (template.kind === 'kids_blue' || template.kind === 'kids_silver') setKind('kids');
	}, [shippingData.method]);

	return (
		<div>
			<div className="flex justify-between">
				<p className="text-md mb-[10px] mt-[10px]">
					{t('pages.cards.card.item.cardType')} {t(`pages.cards.card.item.kinds.${kind}`)}
				</p>
			</div>
			<div className={`${cardDesign.bgColor} p-2`}>
				<div className={`border ${cardDesign.borderColor} h-full p-5`}>
					<div className="h-full">
						<div className="flex items-start justify-between">
							<div className={`${cardDesign.limitTextColor}`}>
								<p>{t('pages.cards.card.item.limit')}</p>
								<p className="text-xxl font-bolder">{template.currency.symbol + numeral(template.limit).format('0,0')} </p>
							</div>
							<img src={`/assets/card/${cardDesign.logoPath}`} />
						</div>
						<div className="flex items-end justify-between">
							<div className={`${cardDesign.cardDetailsTextColor} text-md mt-[25px]`}>
								<p className="text-lg font-bolder">**** **** **** ****</p>
								<p>{t('pages.cards.card.item.exp')} **/**</p>
							</div>
							<div className="flex flex-col justify-between">
								<img src="/assets/card/card_apple.svg" className="mb-[5px]" />
								<img src="/assets/card/card_google.svg" />
								<img src="/assets/card/card_mastercard.svg" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<GradientButton
				text={t('buttons.apply')}
				className="mt-2 !text-center"
				onClick={() => (template.type === 'virtual' ? setIsVirtualAppyModalOpen(true) : setIsPhysicalApplyModalOpen(true))}
			/>

			{/** MOVE TO STANDALONE MODAL */}
			<CustomModal
				isOpen={isVirtualApplyModalOpen}
				onClose={() => setIsVirtualAppyModalOpen(false)}
				title={t('modals.cards.apply.virtual.title')}
				subTitle={`${
					template.cost > 0 ? t('modals.cards.apply.virtual.subTitle.cost') + template.cost : t('modals.cards.apply.virtual.subTitle.cost.free')
				}`}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							{template.cost > 0 && (
								<div>
									<p className="text-lg font-bold mt-5">{t('modals.cards.apply.virtual.paymentMethod.title')}</p>
									<SelectInput
										options={['paytora', 'iban'].map((method) => ({
											value: method,
											label: method.toUpperCase(),
										}))}
										defaultValue={{ value: selectedFromMethod, label: selectedFromMethod.toUpperCase() }}
										onChange={setSelectedFromMethod}
									/>
								</div>
							)}
							<div className="mt-5">
								<input
									type="checkbox"
									id="termsCheckbox"
									checked={conditions.agreeToTerms}
									onChange={() => handleCheckboxChange('agreeToTerms')}
									className="me-2"
								/>
								<label htmlFor="termsCheckbox" className="ml-2">
									{t('termsAndPrivacy.title')}
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/Combined_Terms_and_Conditions.pdf', '_self')}>
										{t('termsAndPrivacy.terms')}
									</span>
								</label>
							</div>

							<div className="mb-[20px]">
								<input
									type="checkbox"
									id="privacyCheckbox"
									checked={conditions.agreeToPrivacyPolicy}
									onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
									className="me-2"
								/>
								<label htmlFor="privacyCheckbox" className="ml-2">
									{t('termsAndPrivacy.title')}
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_self')}>
										{t('termsAndPrivacy.privacy')}
									</span>
								</label>
							</div>

							{errMsg && <ErrorBubble error={errMsg} />}
							<div className="flex flex-col items-center mt-[10px]">
								<PrimaryButton text={t('buttons.apply')} textColor="text-white" onClick={apply} />
								<SecondaryButton text={t('buttons.close')} onClick={() => setIsVirtualAppyModalOpen(false)} textColor="text-black" />
							</div>
						</div>
					)
				}
			/>

			{/** MOVE TO STANDALONE MODAL */}
			<CustomModal
				isOpen={isPhysicalApplyModalOpen}
				onClose={() => setIsPhysicalApplyModalOpen(false)}
				title={t('modals.cards.apply.physical.title')}
				subTitle={`${t('modals.cards.apply.physical.subTitle.cost')}${template.cost}`}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<p className="mt-2 mb-2">{t('modals.cards.apply.physical.delivery.title')}</p>
							<div>
								<div className="inputs-container">
									<SelectInput
										defaultValue={{ value: CountriesList[0], label: CountriesList[0] }}
										onChange={(value) => {
											setShippingData((data) => ({
												...data,
												country: value,
											}));
										}}
										options={CountriesList.map((opt) => ({
											value: opt,
											label: opt,
										}))}
										placeHolder={t('inputs.placeholders.country')}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, city: value }));
										}}
										placeholder={t('inputs.placeholders.city')}
										type="text"
										value={shippingData.city}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, street: value }));
										}}
										placeholder={t('inputs.placeholders.street')}
										type="text"
										value={shippingData.street}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, number: value }));
										}}
										placeholder={t('inputs.placeholders.apartment')}
										type="text"
										value={shippingData.number}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, postCode: value }));
										}}
										placeholder={t('inputs.placeholders.postalCode')}
										type="text"
										value={shippingData.postCode}
									/>

									<SelectInput
										defaultValue={shippingMethodOptions[0]}
										onChange={(value) => {
											setShippingData((data) => ({
												...data,
												method: value,
											}));
										}}
										options={shippingMethodOptions.map((opt) => ({
											value: opt.value,
											label: opt.label,
										}))}
										placeHolder={t('inputs.placeholders.shippingMethod')}
									/>

									<p className="text-lg font-bold mt-5">{t('modals.cards.apply.physical.paymentMethod.title')}</p>
									<SelectInput
										options={['paytora', 'iban'].map((method) => ({
											value: method,
											label: method.toUpperCase(),
										}))}
										defaultValue={{ value: selectedFromMethod, label: selectedFromMethod.toUpperCase() }}
										onChange={setSelectedFromMethod}
									/>
								</div>

								<div>
									<input
										type="checkbox"
										id="termsCheckbox"
										checked={conditions.agreeToTerms}
										onChange={() => handleCheckboxChange('agreeToTerms')}
										className="me-2"
									/>
									<label htmlFor="termsCheckbox" className="ml-2">
										{t('termsAndPrivacy.title')}
										<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/Combined_Terms_and_Conditions.pdf', '_blank')}>
											{t('termsAndPrivacy.terms')}
										</span>
									</label>
								</div>
								<div className="mb-[10px]">
									<input
										type="checkbox"
										id="privacyCheckbox"
										checked={conditions.agreeToPrivacyPolicy}
										onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
										className="me-2"
									/>
									<label htmlFor="privacyCheckbox" className="ml-2">
										{t('termsAndPrivacy.title')}
										<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_blank')}>
											{t('termsAndPrivacy.privacy')}
										</span>
									</label>
								</div>

								{errMsg && <ErrorBubble error={errMsg} />}
								{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
								<div className="flex flex-col items-center mt-[10px]">
									<p className="text-red mt-[10px] mb-[10px]">
										{t('modals.cards.apply.physical.addtional.fee.first')}
										{shippingFee} {t('modals.cards.apply.physical.addtional.fee.second')}
									</p>
									<PrimaryButton text={t('buttons.apply')} textColor="text-white" onClick={apply} />
									<SecondaryButton text={t('buttons.close')} onClick={() => setIsPhysicalApplyModalOpen(false)} textColor="text-black" />
								</div>
							</div>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={t('modals.cards.apply.success.title')}
				subTitle={`${
					template.type === 'virtual' ? t('modals.cards.apply.success.virtual.subTitle') : t('modals.cards.apply.success.physical.subTitle')
				}`}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setIsSuccessModalOpen(false);
								window.location.reload();
							}}
							text={t('buttons.close')}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
};
