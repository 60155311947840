import { apiRequest } from './api';

export const createVerestroIbanAPI = async () => {
	try {
		const response = await apiRequest('/user/ibans', 'POST');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getVerestroIbanAPI = async (cardId) => {
	try {
		const response = await apiRequest('/user/iban/details', 'POST', { cardId });

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getVerestroIbanBalanceAPI = async () => {
	const hasIban = JSON.parse(localStorage.getItem('hasIban'));
	if (!hasIban || hasIban === 'false') return { isSuccess: false, error: 'user have no iban' };

	try {
		const response = await apiRequest('/user/ibans/balance', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const withdrawalAPI = async (data) => {
	try {
		const response = await apiRequest('/user/withdrawal', 'POST', data);

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const transferToUserFromIban = async (data) => {
	try {
		const response = await apiRequest('/user/ibans/transfer/member', 'POST', data);

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const ibanWireTransfer = async (data) => {
	try {
		await apiRequest('/user/ibans/transfer/wire', 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const transferToCardFromIban = async (data) => {
	try {
		await apiRequest('/user/ibans/transfer/card', 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getDetailsByIbanAPI = async (iban) => {
	try {
		const response = await apiRequest('/user/withdrawal/iban/details/' + iban, 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
