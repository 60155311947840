import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchUserAccounts } from '../../store/accountsSlice';

import Median from 'median-js-bridge';

import Loader from '../utils/loader';

import { getUserCardsBalanceAPI } from '../../services/cards';
import { getVerestroIbanBalanceAPI } from '../../services/ibans';
import { getUserTransactionsAPI } from '../../services/user';

import { AccountDetails } from '../../components/dashboard/accountDetails';
import { AccountBalance } from '../../components/dashboard/accountBalance';
import { CardsBalance } from '../../components/dashboard/cardsBalance';
import { IbanBalance } from '../../components/dashboard/ibanBalance';
import { DashboardActions } from '../../components/dashboard/dashboardActions';

import { BottomMenu } from '../../components/navigation/bottomMenu';
import { DashboardHeader } from '../../components/navigation/dashboardHeader';

import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { CustomModal } from '../../components/modals/customModal';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { useTranslation } from 'react-i18next';

export default function Dashboard() {
	const dispatch = useDispatch();
	const { userWallets, totalBalance } = useSelector((state) => state.accounts);

	const { t } = useTranslation();

	const [cardsBalance, setCardsBalance] = useState(0);

	const [ibanBalance, setIbanBalance] = useState(0);

	const [isLoading, setIsLoading] = useState(false);

	const [isAppDownloadModalOpen, setIsAppDownloadModalOpen] = useState(false);

	const [transactions, setTransactions] = useState();

	const hasIban = JSON.parse(localStorage.getItem('hasIban'));

	useEffect(() => {
		setIsAppDownloadModalOpen(!Median.isNativeApp());
	}, []);

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	useEffect(() => {
		const loadDashboard = async () => {
			setIsLoading(true);
			const cardsBalanceResponse = await getUserCardsBalanceAPI();
			if (cardsBalanceResponse.isSuccess) setCardsBalance(cardsBalanceResponse.balance);

			const ibanBalanceResponse = hasIban && hasIban === true ? await getVerestroIbanBalanceAPI() : { isSuccess: false };

			if (ibanBalanceResponse.isSuccess) setIbanBalance(ibanBalanceResponse.data);

			setIsLoading(false);
		};

		loadDashboard();
	}, [hasIban]);

	useEffect(() => {
		const getTransactionsByType = async () => {
			setIsLoading(true);
			const response = await getUserTransactionsAPI('all');
			setTransactions(response.data);

			setIsLoading(false);
		};

		getTransactionsByType();
	}, []);

	useEffect(() => {
		localStorage.setItem('totalBalance', totalBalance);
	}, [totalBalance]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="bg-lighterGray p-5 pb-[100px]">
			<DashboardHeader />
			<AccountDetails />
			<div className="relative z-10">
				<AccountBalance userWallets={userWallets} />
				<CardsBalance cardsBalance={cardsBalance} />
			</div>
			<IbanBalance ibanBalance={ibanBalance} />
			<DashboardActions />
			<TransactionsContainer transactions={transactions} />

			<BottomMenu />

			<CustomModal
				isOpen={isAppDownloadModalOpen}
				onClose={() => setIsAppDownloadModalOpen(false)}
				title={t('modals.dashboard.application_download.title')}
				subTitle={t('modals.dashboard.application_download.subTitle')}
				children={
					<div>
						<div className="flex justify-center items-center">
							<a href="https://play.google.com/store/apps/details?id=com.paytora.app">
								<img src="/assets/application/google_store.png" className="w-[150px]" alt="google_app" />
							</a>
							<a href="https://apps.apple.com/il/app/paytora-digital-bank/id6612336703?l=he">
								<img src="/assets/application/apple_store.png" className="w-[150px]" alt="apple_app" />
							</a>
						</div>

						<div className="flex flex-col justify-center mt-[20px]">
							<PrimaryButton
								onClick={() => {
									setIsAppDownloadModalOpen(false);
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
