import { z } from 'zod';

export const Card2CardVerifySchema = (t) =>
	z.object({
		cardNumber: z
			.string()
			.trim()
			.min(16, { message: t('errors.validations.cardNumber') }),
		customerNumber: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.customerNumber') }),
	});
