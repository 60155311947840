import { useState } from 'react';

const useValidation = (schema) => {
	const [validationErrMsg, setValidationErrMsg] = useState('');

	const validate = (data) => {
		const checkInput = schema.safeParse(data);

		if (checkInput.error) {
			const message =
				checkInput.error.issues.length > 0 ? checkInput.error.issues[0].message : 'Error occurred, please try again or contact support!';

			setValidationErrMsg(message);

			return false;
		}

		setValidationErrMsg('');

		return true;
	};

	return { validationErrMsg, validate };
};

export default useValidation;
