import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const BottomMenu = ({ selected = 'home' }) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	return (
		<div className="p-5 fixed bottom-0 left-0 w-full bg-white shadow-lg border-t border-lightGray z-50" dir={langDir}>
			<div className="flex justify-between">
				<div className={`flex flex-col items-center ${selected === 'home' && 'font-bolder'}`} onClick={() => navigate('/')}>
					<img src="/assets/icons/home_menu.svg" className="h-[25px] w-[25px]" />
					<p>{t('pages.dashboard.menu.home')}</p>
				</div>
				<div className={`flex flex-col items-center ${selected === 'cards' && 'font-bolder'}`} onClick={() => navigate('/cards')}>
					<img src="/assets/icons/cards_menu.svg" className="h-[25px] w-[25px]" />
					<p>{t('pages.dashboard.menu.cards')}</p>
				</div>
				<div className={`flex flex-col items-center ${selected === 'more' && 'font-bolder'}`} onClick={() => navigate('/menu')}>
					<img src="/assets/icons/more_menu.svg" className="h-[25px] w-[25px]" />
					<p>{t('pages.dashboard.menu.more')}</p>
				</div>
			</div>
		</div>
	);
};
