import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

export default function PaytoraTransactionItem({ transaction }) {
	const { t } = useTranslation();

	return (
		<div>
			<div className="p-5 mb-[10px]">
				<div className="flex justify-between mb-[10px]">
					<h1 className="text-lg text-gradient">{t(`pages.transactions.item.types.${transaction.type}`)}</h1>
					<p className="text-sm">
						{t('pages.transactions.item.status')} -{' '}
						<span className={`${transaction.status === 'completed' ? 'text-green' : 'text-orange'}`}>
							{t(`pages.transactions.item.statuses.${transaction.status}`)}
						</span>
					</p>
				</div>
				<div className="flex justify-between">
					<div className="flex">
						<img src="/assets/transactions/transfer.svg" className="me-[10px]" />
						<div className="flex flex-col text-xsm justify-center">
							{transaction.receiver && !transaction.isReceiver && <p className="text-md">{transaction.receiver.fullName}</p>}
							{transaction.receiver && transaction.isReceiver && <p className="text-md">{transaction.creator}</p>}
							{transaction.description && <p>{transaction.description}</p>}
							{transaction.paymentMethod && (
								<p>
									{t(`pages.transactions.item.method`)}: {transaction.paymentMethod}
								</p>
							)}
						</div>
					</div>
					<div>
						{transaction.type === 'swap' ? (
							<div className="flex">
								<p className="text-lg text-red">
									{transaction.currencySymbol}
									{numeral(transaction.amount).format('0,0.00')}
								</p>
								<img src="/assets/icons/exchange_horizontal.svg" className="me-2 ms-2" />
								<p className="text-lg text-green">
									{transaction.exchangedCurrencySymbol}
									{numeral(transaction.exchangedAmount).format('0,0.00')}
								</p>
							</div>
						) : (
							<p
								className={`text-lg ${
									transaction.type === 'deposit' || (transaction.receiver && transaction.isReceiver) ? 'text-green' : 'text-red'
								}`}
							>
								{transaction.currencySymbol}
								{numeral(transaction.amount).format('0,0.00')}
							</p>
						)}
					</div>
				</div>
				<div className="text-sm flex justify-between mt-2">
					<p>{transaction.createdAt}</p>
					<h1 className="text-gradient text-end">{transaction.kind}</h1>
				</div>
			</div>
		</div>
	);
}
