import React, { useState } from 'react';

import Loader from '../utils/loader';

import { PincodeInput } from '../../components/inputs/pincodeInput';
import { Header } from '../../components/navigation/header';

import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { setUserPincode } from '../../services/pincode';
import useValidation from '../../hooks/useValidation';
import { SetPincodeSchema } from '../../utils/validations/user/user_schema';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { useTranslation } from 'react-i18next';

export default function SetPincode() {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));
	const [confirmPincodeValues, setConfirmPincodeValues] = useState(Array(6).fill(''));

	const { validationErrMsg, validate } = useValidation(SetPincodeSchema);
	const [apiErrorMsg, setApiErrorMsg] = useState('');

	const handleSetPincode = async (event) => {
		event.preventDefault();
		const pincode = pincodeValues.join('');
		const confirmPincode = confirmPincodeValues.join('');

		const isValid = validate({ pincode, confirmPincode });
		if (!isValid) return setApiErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);
			const response = await setUserPincode(pincode);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setApiErrorMsg(response.error);
			}

			localStorage.setItem('hasPincode', true);

			setIsLoading(false);

			window.location.reload();
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<form onSubmit={handleSetPincode} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header title={t('pages.setPincode.title')} hasBack={false} hasIcon={true} />
				<h1 className="text-xl text-center font-bolder mt-[40px]">{t('pages.setPincode.header')}</h1>

				<InstructionBubble title={t('pages.setPincode.warning.title')} text={t('pages.setPincode.warning.text')} className="mt-[5px]" />

				<h2 className="text-lg text-center font-bolder mt-[40px]">{t('pages.setPincode.input.first.title')}</h2>

				<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="mt-[20px]" />

				<h2 className="text-lg text-center font-bolder mt-[40px]">{t('pages.setPincode.input.second.title')}</h2>

				<PincodeInput onChange={(value) => setConfirmPincodeValues(value)} pincode={confirmPincodeValues} className="mt-[20px]" />
			</div>

			<div className="text-center">
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrorMsg && <ErrorBubble error={apiErrorMsg} />}
				<PrimaryButton text={t('buttons.confirm')} className="mb-[10px]" />
			</div>
		</form>
	);
}
