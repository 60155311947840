import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserProfileAPI } from '../../services/user';

import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';

import Loader from '../utils/loader';

import { convertDateToString } from '../../utils/functions/date';
import { useTranslation } from 'react-i18next';

export default function UserProfile() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const [userProfile, setUserProfile] = useState(null);
	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getUserProfile = async () => {
			setIsLoading(true);

			const response = await getUserProfileAPI();
			if (response.isSuccess) {
				setUserProfile(response.data);
			} else setErrMsg(t('errors.server.LOAD_PROFILE'));

			setIsLoading(false);
		};

		getUserProfile();
	}, [t]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5">
			<Header title={t('pages.profile.header')} />
			<AccountDetailsSubHeader showBalance={false} className="mt-[10px]" />

			{userProfile && errMsg.length === 0 ? (
				<div dir={langDir}>
					<h1 className="text-xl font-bolder mb-[20px]">{t('pages.profile.nationalityAndPassport')}</h1>
					<p className="text-darkGray text-md">{t('pages.profile.nationality')}</p>
					<p className="text-lg mb-[20px]">****</p>
					<p className="text-darkGray text-md">{t('pages.profile.passportExpiry')}</p>
					<p className="text-lg mb-[40px]">{convertDateToString(userProfile.documentExpiryDate)}</p>
					{/* <p className="text-lg mb-[40px]">{Date(userProfile.documentExpiryDate)}</p> */}

					<h1 className="text-xl font-bolder mb-[20px]">{t('pages.profile.contactDetails')}</h1>
					<p className="text-darkGray text-md">{t('pages.profile.fullName')}</p>
					<p className="text-lg mb-[20px]">{userProfile.user.firstName + ' ' + userProfile.user.lastName}</p>
					<p className="text-darkGray text-md">{t('pages.profile.phone')}</p>
					<p className="text-lg mb-[20px]">+{userProfile.user.phoneNumber}</p>
					<p className="text-darkGray text-md">{t('pages.profile.email')}</p>
					<p className="text-lg mb-[20px]">{userProfile.user.email}</p>

					<div className="flex justify-between mb-[20px]">
						<h1 className="text-xl font-bolder ">{t('pages.profile.addressDetails')}</h1>
						<img src="/assets/icons/edit.svg" onClick={() => navigate('/profile/address/edit')} alt="edit" />
					</div>
					<p className="text-darkGray text-md">{t('pages.profile.currentAddress')}</p>
					<p className="text-lg mb-[20px]">
						{(
							userProfile.street.toUpperCase() +
							' ' +
							userProfile.number +
							'/' +
							userProfile.apartment +
							', ' +
							userProfile.city.toUpperCase() +
							', ' +
							userProfile.postalCode
						)
							.toLowerCase() // Convert the entire string to lowercase first
							.split(' ') // Split by spaces to handle each word individually
							.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
							.join(' ') +
							', ' +
							userProfile.country}
					</p>

					<div className="flex justify-center">
						<PageLogo />
					</div>
				</div>
			) : (
				<p>{errMsg}</p>
			)}
		</div>
	);
}
