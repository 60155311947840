import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { getDecodedToken } from '../../utils/functions/token';

export default function IntercomUserChat({ logoBlack = false, isDashboard = false }) {
	const decodedToken = getDecodedToken();

	const langDir = localStorage.getItem('languageDir');

	useEffect(() => {
		// Initialize Intercom with custom launcher
		Intercom({
			app_id: 'jbfiwclz',
			user_id: decodedToken.userId,
			name: decodedToken.firstName + ' ' + decodedToken.lastName,
			email: decodedToken.email,
			user_hash: decodedToken.intercomId,
			custom_launcher_selector: '#intercom-custom-launcher',
			hide_default_launcher: true,
			language_override: localStorage.getItem('language'),
		});
	}, [decodedToken]);

	const extraClassName = `${langDir === 'rtl' ? `left-0 ${!isDashboard && '!me-[18px]'}` : `right-0 ${isDashboard ? '!me-[40px]' : '!me-[60px]'}`}`;

	return (
		<div id="intercom-custom-launcher" className={`intercom-custom-launcher ${extraClassName} !mt-[4px]`}>
			<img src={logoBlack ? '/assets/icons/chat.svg' : '/assets/icons/chat_white.svg'} />
		</div>
	);
}
