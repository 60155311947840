import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMessagesAPI, updateUnreadMessagesAPI } from '../../services/messages';

import { convertDateAndTimeToString } from '../../utils/functions/date';

import { Header } from '../../components/navigation/header';

import Loader from '../utils/loader';
import { useTranslation } from 'react-i18next';

export default function Notifications() {
	const { t, i18n } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const location = useLocation();
	const { hasMessages } = location.state || false;

	const [messages, setMessages] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const userMessages = await getMessagesAPI();
			setMessages(userMessages.data);

			if (hasMessages) await updateUnreadMessagesAPI();

			setIsLoading(false);
		};

		fetchData();
	}, [hasMessages]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5" dir={langDir}>
			<Header title={t('pages.messages.header')} />
			<div>
				{messages.length > 0 ? (
					messages.map((message) => (
						<div className="flex mb-[20px]">
							<img src="/assets/icons/notification_message.svg" className="me-[20px]" alt="notification_message" />

							<div className="flex flex-col">
								<p className={`text-md ${!message.isRead ? 'font-bolder' : ''}`}>{message.content[i18n.language]}</p>
								<p className="text-sm">{convertDateAndTimeToString(message.createdAt)}</p>
							</div>
						</div>
					))
				) : (
					<div className="text-center text-xl mt-10">{t('pages.messages.label.noMessages')}</div>
				)}
			</div>
		</div>
	);
}
