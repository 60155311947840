import Median from 'median-js-bridge';

import { login } from '../../../services/auth';

export const medianBioLogin = async () => {
	await Median.auth.status().then((data) => {
		if (data.hasTouchId && data.hasSecret) {
			Median.auth.get().then(async (data) => {
				if (data && data.success && data.secret) {
					const credentials = JSON.parse(data.secret);
					const username = credentials.username;
					const password = credentials.password;

					const response = await login(username, password);

					if (!response.isSuccess) {
						return { isSuccess: false, error: response.error };
					} else {
						return { isSuccess: true, isLogin: true };
					}
				}
				return { isSuccess: true, isLogin: false };
			});
		}
		return { isSuccess: true, isLogin: false };
	});
	return { isSuccess: true, isLogin: false };
};
