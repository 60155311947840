import { z } from 'zod';
import { validationErrors } from '../../text/errors.text';

export const forgotPasswordSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('errors.validations.email') }),
	});

export const forgotPasswordConfirmSchema = (t) =>
	z
		.object({
			email: z.string().email({ message: t('errors.validations.email') }),
			password: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.password') })
				.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
					message: t('passwordInstructions.message'),
				}),
			confirmPassword: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.confirmPassword') }),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('errors.validations.passwordsNotMatch'),
		});

export const ChangePasswordSchema = z
	.object({
		currentPassword: z
			.string()
			.trim()
			.min(1, { message: 'Current password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		newPassword: z
			.string()
			.trim()
			.min(1, { message: 'New password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		confirmNewPassword: z.string().trim().min(1, { message: 'Confirm new password can not be empty!' }),
	})
	.refine((data) => data.newPassword === data.confirmNewPassword, {
		message: validationErrors.password_not_match,
	});

export const SetPincodeSchema = z
	.object({
		pincode: z.string().trim().min(6, { message: 'Pincode most be 6 digits length' }),
		confirmPincode: z.string().trim().min(1, { message: 'Confirm pincode most be 6 digits length' }),
	})
	.refine((data) => data.pincode === data.confirmPincode, {
		message: 'Pincode are not matched!',
	});
