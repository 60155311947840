import { apiRequest } from './api';

export async function getMessagesAPI() {
	try {
		const response = await apiRequest('/user/messages', 'GET', null);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export const sendAdminMessageAPI = async (data) => {
	try {
		const response = await apiRequest('/user/admin-message', 'POST', data);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getUnreadMessagesCountAPI = async () => {
	try {
		const response = await apiRequest('/user/messages/count', 'GET', null);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const updateUnreadMessagesAPI = async () => {
	try {
		await apiRequest('/user/messages', 'PUT', null);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
