import { apiRequest } from './api';

export async function swapCurrencyAPI(data) {
	try {
		const response = await apiRequest('/user/swap', 'POST', data);
		return { isSuccess: true, data: response.exchangedAmount, exchangedRate: response.exchangeRate };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}

	// if (result.status === 200) {
	// 	return { isSuccess: true, data: jsonResult.exchangedAmount, exchangedRate: jsonResult.exchangeRate };
	// }

	// if (result.status === 201) {
	// 	return { isSuccess: true };
	// }
}
