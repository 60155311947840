import { z } from 'zod';

export const IbanDataWithdrawSchema = (t) =>
	z
		.object({
			wireType: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.wireType') }),
			receiverFullName: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.fullName') }),
			receiverIBAN: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.iban') }),
			receiverStreetAndNumber: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.streetAndNumber') }),
			receiverPostalCode: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.postalCode') }),
			receiverCity: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.city') }),
			receiverBIC: z.string().trim().optional(),
		})
		.refine(
			(data) => {
				// Require receiverBIC if wireType is 'SWIFT'
				return data.wireType !== 'SWIFT' || data.receiverBIC;
			},
			{
				message: t('errors.validations.bic'),
				path: ['receiverBIC'],
			}
		);
