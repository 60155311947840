import { useTranslation } from 'react-i18next';

import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';

export default function SuspendedPage() {
	const { t } = useTranslation();

	return (
		<div className="p-5 h-screen flex flex-col justify-between">
			<div>
				<Header title={t('pages.suspended.title')} />
			</div>
			<h1 className="text-2xl text-center text-red uppercase font-bold" dir="rtl">
				{t('pages.suspended.message')}
			</h1>

			<PageLogo className="opacity-[50%] mb-20" />
		</div>
	);
}
