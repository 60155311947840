import { apiRequest } from './api';

export async function getFeeByType(data) {
	try {
		const response = await apiRequest('/user/fee/type', 'POST', data);

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
