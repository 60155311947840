import React, { useState } from 'react';

import { CustomModal } from './customModal';

import { PrimaryButton } from '../buttons/primary-button';

import { PincodeInput } from '../inputs/pincodeInput';

import { SecondaryButton } from '../buttons/secondary-button';

import { verifyUserPincode } from '../../services/pincode';
import { useTranslation } from 'react-i18next';

export const PincodeModal = ({ isOpen, onClose, onAction }) => {
	const { t } = useTranslation();

	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [errMsg, setErrMsg] = useState('');

	const pincodeVerify = async () => {
		const pincode = pincodeValues.join('');
		const response = await verifyUserPincode(pincode);

		if (response.isSuccess) {
			onAction();
		} else setErrMsg(response.error);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			title={t('modals.pincode.title')}
			subTitle={t('modals.pincode.subTitle')}
			children={
				<div>
					<p className="text-gradient text-start mb-[20px]">{t('modals.pincode.message')}</p>
					<div className="flex flex-col items-center">
						<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="mb-[20px]" />

						<PrimaryButton
							onClick={async () => {
								await pincodeVerify();
								setPincodeValues(Array(6).fill(''));
							}}
							text={t('buttons.verify')}
							textColor="text-white"
						/>
						<SecondaryButton
							onClick={() => {
								onClose();
								setPincodeValues(Array(6).fill(''));
							}}
							text={t('buttons.close')}
							textColor="text-black"
						/>
						<p className="text-red">{errMsg}</p>
					</div>
				</div>
			}
		/>
	);
};
