import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import './i18n';

import { isMobile } from 'react-device-detect';

import { getStorageToken, handleTokenExpiration } from './utils/functions/token';

import Login from './pages/auth/login';
import Register from './pages/auth/register.jsx';

import Dashboard from './pages/dashboard/dashboard';

import NotFound404 from './pages/utils/404';
import ResultPage from './pages/utils/result-page';
import SuspendedPage from './pages/utils/suspended';
import OnlyMobilePage from './pages/utils/onlyMobile.jsx';

import UserProfile from './pages/auth/userProfile.jsx';
import ForgotPassword from './pages/auth/forgotPassword.jsx';

import TermsAndConditions from './pages/terms/terms-and-conditions';
import PrivacyPolicy from './pages/terms/privacy-policy';
import CardTermsAndConditions from './pages/terms/card-terms-and-conditions.jsx';
import CardPrivacyPolicy from './pages/terms/card-privacy-policy.jsx';
import KYCProcess from './pages/kyc/kycProcess.jsx';

import IbanTermsAndConditions from './pages/terms/iban-terms-and-conditions.jsx';

import WelcomePage from './pages/auth/welcome.jsx';
import DepositPage from './pages/actions/deposit/deposit.jsx';
import SendPage from './pages/actions/send/send.jsx';

import SetPincode from './pages/auth/setPincode.jsx';
import Withdrawal from './pages/actions/send/withdrawal.jsx';
import Menu from './pages/menu/menu.jsx';
import Notifications from './pages/messages/messages.jsx';
import ChangePassword from './pages/auth/changePassword.jsx';

import Cards from './pages/cards/cards.jsx';
import CardDepositPage from './pages/cards/card_deposit.jsx';
import WireTransfer from './pages/actions/deposit/wire_transfer.jsx';
import PaytoraMemberTransfer from './pages/actions/send/paytora_transfer.jsx';
import IbanTransactions from './pages/transactions/ibanTransactions.jsx';
import SwapCurrency from './pages/swap/swapCurrency.jsx';
import CardTransactions from './pages/transactions/cardsTransactions.jsx';
import PayToraTransactions from './pages/transactions/paytoraTransactions.jsx';
import EditProfileAddress from './pages/auth/editProfileAddress.jsx';
import CardToCard from './pages/cards/cardToCard.jsx';
import FAQ from './pages/faq/faq.jsx';

const AuthenticatedRoute = ({ element, kycRequired = false }) => {
	const token = getStorageToken();
	const kycValue = localStorage.getItem('kyc') ?? 'none';
	const suspended = JSON.parse(localStorage.getItem('isSuspended'));
	const hasPincode = JSON.parse(localStorage.getItem('hasPincode'));

	useEffect(() => {
		if (token) {
			handleTokenExpiration(token);
		}
	}, [token]);

	if (!token) {
		return <WelcomePage />; // Translation - Done
	}

	if (suspended) {
		return <SuspendedPage />; // Translation - Done
	}

	if (kycRequired && kycValue !== 'completed') {
		return <KYCProcess />; // Translation - Done
	}

	if (!hasPincode) {
		return <SetPincode />;
	}

	return element;
};

function RoutesWrapper() {
	const location = useLocation();
	const isRegisterPage = location.pathname === '/register';

	if (!isMobile && !isRegisterPage) {
		return <OnlyMobilePage />; // Redirect desktop users unless on /register
	}

	return (
		<Routes>
			<Route path="/login" element={<Login />} /> {/** Translation: Done */}
			<Route path="/register" element={<Register />} /> {/** Translation: Done */}
			<Route path="/forgot-password" element={<ForgotPassword />} /> {/** Translation: Done */}
			<Route path="/terms" element={<TermsAndConditions />}></Route> {/** Translation: Check if requried */}
			<Route path="/privacy" element={<PrivacyPolicy />}></Route> {/** Translation: Check if requried */}
			<Route path="/success" element={<ResultPage isSuccess={true} />}></Route> {/** Translation: Done */}
			<Route path="/failed" element={<ResultPage />}></Route> {/** Translation: Done  */}
			<Route path="/404" element={<NotFound404 />} /> {/** Translation: Check if required  */}
			<Route path="*" element={<Navigate to="/404" />} />
			{/* Authenticated Routes */}
			<Route path="/" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />
			{/** Translation: Done (missing transaction items)  */}
			<Route path="/kyc" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />
			<Route path="/menu" element={<AuthenticatedRoute element={<Menu />} kycRequired={true} />} /> {/** Translation: Done  */}
			{/** Translation: Done  */}
			<Route path="/profile" element={<AuthenticatedRoute element={<UserProfile />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/profile/address/edit" element={<AuthenticatedRoute element={<EditProfileAddress />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/profile/password" element={<AuthenticatedRoute element={<ChangePassword />} kycRequired={true} />} />
			<Route path="/messages" element={<AuthenticatedRoute element={<Notifications />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/deposit" element={<AuthenticatedRoute element={<DepositPage />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/send" element={<AuthenticatedRoute element={<SendPage />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/send/withdrawal" element={<AuthenticatedRoute element={<Withdrawal />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/send/paytora-member/:currencyCode?" element={<AuthenticatedRoute element={<PaytoraMemberTransfer />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/swap-currency" element={<AuthenticatedRoute element={<SwapCurrency />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/cards" element={<AuthenticatedRoute element={<Cards />} kycRequired={true} />} />
			{/** Translation: Done   */}
			<Route path="/card/deposit" element={<AuthenticatedRoute element={<CardDepositPage />} kycRequired={true} />} />
			{/** Translation: Check if required   */}
			<Route path="/card/terms" element={<AuthenticatedRoute element={<CardTermsAndConditions />} kycRequired={true} />} />
			{/** Translation: Check if required   */}
			<Route path="/card/privacy" element={<AuthenticatedRoute element={<CardPrivacyPolicy />} kycRequired={true} />} />
			{/** Translation: Done   */}
			<Route path="/card-to-card" element={<AuthenticatedRoute element={<CardToCard />} kycRequired={true} />} />
			{/** Translation:  Done  */}
			<Route path="/iban/details" element={<AuthenticatedRoute element={<WireTransfer />} kycRequired={true} />} />
			{/** Translation: Check if required   */}
			<Route path="/iban/terms" element={<IbanTermsAndConditions />}></Route>
			{/** Translation:  Done  */}
			<Route path="/transactions/paytora/:currency?" element={<AuthenticatedRoute element={<PayToraTransactions />} kycRequired={true} />} />
			{/** Translation: Done */}
			<Route path="/transactions/cards" element={<AuthenticatedRoute element={<CardTransactions />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/transactions/iban" element={<AuthenticatedRoute element={<IbanTransactions />} kycRequired={true} />} />
			{/** Translation: Done  */}
			<Route path="/faq" element={<AuthenticatedRoute element={<FAQ />} kycRequired={true} />} />
		</Routes>
	);
}

function App() {
	return (
		<Router>
			<RoutesWrapper />
		</Router>
	);
}

export default App;
