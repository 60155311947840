import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';

export default function NotFound404() {
	const navigate = useNavigate();

	return (
		<div className="p-5 h-screen flex flex-col justify-between">
			{/* <HeaderOld showUser={false} showBalance={false} showBack={false} /> */}
			<Header hasIcon={true} hasBack={false} showUserChat={false} />
			<div className="text-center">
				<p className=" text-lg font-bold">
					Opps, <br /> Something Went Wrong!
				</p>
				<h1 className="text-[100px] text-gradient font-bolder">404</h1>
			</div>
			<PageLogo className="mb-20" />
			<PrimaryButton textColor="text-white" text="Return To Home" onClick={() => navigate('/')} />
		</div>
	);
}
