export const GradientButton = ({ onClick, text, iconPath, className }) => {
	return (
		<div className={`border-gradient w-full mb-2 ${className}`} onClick={onClick}>
			<div className={`bg-white rounded-[6px] h-[50px] flex items-center p-5 ${!iconPath ? 'justify-center' : ''}`}>
				<button type="button">
					<div className="flex justify-between">
						{iconPath ? <img src={`/assets/${iconPath}`} className="me-2" /> : ''}
						<p className="text-black me-5">{text}</p>
					</div>
				</button>
			</div>
		</div>
	);
};
