import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import { forgotPassword, forgotPasswordConfirm } from '../../services/auth';

import { Input } from '../../components/inputs/textInput';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { PincodeInput } from '../../components/inputs/pincodeInput';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import PasswordInput from '../../components/inputs/passwordInput';
import { PageLogo } from '../../components/utils/pageLogo';

import { forgotPasswordConfirmSchema, forgotPasswordSchema } from '../../utils/validations/user/user_schema';
import Loader from '../utils/loader';
import { getStorageToken } from '../../utils/functions/token';

export default function ForgotPassword() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const { validationErrMsg, validate } = useValidation(forgotPasswordSchema(t));
	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(forgotPasswordConfirmSchema(t));

	const [apiErrMsg, setApiErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		otp: Array(6).fill(''),
	});

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleForgotPassword = async (event) => {
		event.preventDefault();

		const isValid = validate({ email: formData.email });
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPassword(formData.email);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`errors.server.${response.error}`));
			} else {
				setApiErrorMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleForgotPasswordConfirm = async (event) => {
		event.preventDefault();

		const otpString = formData.otp.join('');

		if (otpString.length < 4) return setApiErrorMsg(t('errors.validations.otp'));

		const isValid = otpValidate({
			email: formData.email,
			otp: formData.otp,
			password: formData.password,
			confirmPassword: formData.confirmPassword,
		});

		if (!isValid) return setApiErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPasswordConfirm(formData.email, otpString, formData.password);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`errors.server.${response.error}`));
			} else {
				setApiErrorMsg('');
				navigate('/success');
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : !nextPage ? (
		<form onSubmit={handleForgotPassword} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} title={t('pages.forgotPassword.header')} showLanguage={false} />

				<div dir={langDir}>
					<label className="text-md text-darkGray">{t('pages.forgotPassword.email.label')}</label>
				</div>
				<Input
					placeholder={t('inputs.placeholders.email')}
					onChange={(value) => {
						setFormData((data) => ({ ...data, email: value }));
					}}
					type="text"
					value={formData.email}
				/>

				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
			</div>
			<PageLogo />
			<div className="text-center">
				<PrimaryButton text={t('buttons.continue')} className="mb-[10px]" />
				<Link to="/login" className="text-lg">
					{t('buttons.login')}
				</Link>
			</div>
		</form>
	) : (
		<form onSubmit={handleForgotPasswordConfirm} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} title="Forgot Password" />

				<div dir={langDir}>
					<h1 className="text-xl font-bolder mb-5">{t('pages.forgotPassword.otp.title')}</h1>
					<p className="text-md text-darkGray">{t('pages.forgotPassword.otp.subTitle')}</p>
					<p className="text-md">{formData.email}</p>
				</div>

				<PincodeInput onChange={(value) => setFormData((data) => ({ ...data, otp: value }))} pincode={formData.otp} className="mt-[30px]" />

				<InstructionBubble title={t('passwordInstructions.title')} text={t('passwordInstructions.message')} className="mt-[40px]" />

				<h1 dir={langDir} className="text-xl font-bolder mt-[20px] mb-[20px]">
					{t('pages.forgotPassword.password.title')}
				</h1>

				<div dir={langDir}>
					<label className="text-md text-darkGray">{t('pages.forgotPassword.password.label')}</label>
				</div>

				<PasswordInput
					value={formData.password}
					onChange={(value) => setFormData((data) => ({ ...data, password: value }))}
					placeholder={t('inputs.placeholders.password')}
				/>

				<div dir={langDir}>
					<label className="text-md text-darkGray">{t('pages.forgotPassword.passwordConfirm.label')}</label>
				</div>

				<PasswordInput
					value={formData.confirmPassword}
					onChange={(value) => setFormData((data) => ({ ...data, confirmPassword: value }))}
					placeholder={t('inputs.placeholders.confirmPassword')}
				/>
			</div>
			<div className="flex justify-center">
				<PageLogo className="h-[90px]" />
			</div>
			<div className="text-center">
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
				<PrimaryButton text={t('buttons.resetPassword')} className="mb-[10px]" />
			</div>
		</form>
	);
}
