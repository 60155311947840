import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import numeral from 'numeral';

import Loader from '../utils/loader';

import { swapCurrencyAPI } from '../../services/swap';

import { PrimaryButton } from '../../components/buttons/primary-button';
import { useSelector } from 'react-redux';

import { Header } from '../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';
import { InstructionBubble } from '../../components/utils/InstructionBubble';

import { PageLogo } from '../../components/utils/pageLogo';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { useTranslation } from 'react-i18next';
import { AccountBalance } from '../../components/dashboard/accountBalance';
import { CustomModal } from '../../components/modals/customModal';

const customStyles = {
	control: (provided) => ({
		...provided,
		backgroundColor: '#0000000D',
		borderRadius: '10px',
		border: '0px solid black',
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: 'black',
	}),
	input: (provided) => ({
		...provided,
		color: 'black',
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'black',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '50px',
		display: 'flex',
		alignItems: 'center',
	}),
};

const fromCurrencies = ['USD', 'EUR'];
const toCurrencies = ['EUR', 'USD'];

export default function SwapCurrency() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const [fromAmount, setFromAmount] = useState(0);
	const [toAmount, setToAmount] = useState(0);

	const [fromCurrency, setFromCurrency] = useState(fromCurrencies[0]);
	const [toCurrency, setToCurrency] = useState(toCurrencies[0]);

	const [isLoading, setIsLoading] = useState(false);

	const [countdown, setCountdown] = useState(60);
	const [intervalId, setIntervalId] = useState(null);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const getRates = useCallback(
		async (amount) => {
			const response = await swapCurrencyAPI({ fromCurrency, toCurrency, amount });

			if (!response.isSuccess) return;

			if (!isSuccessModalOpen) setToAmount(response.data);
		},
		[fromCurrency, isSuccessModalOpen, toCurrency]
	);

	const swap = async () => {
		if (fromAmount <= 0) return setErrMsg('Amount required to be more then 0');
		if (toAmount <= 0) return setErrMsg('Exchanged amount required to be more then 0');

		await getRates(fromAmount);
		if (!isLoading) {
			setIsLoading(true);

			const response = await swapCurrencyAPI({
				fromCurrency,
				toCurrency,
				amount: parseFloat(fromAmount),
				confirm: true,
			});

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}
			setIsLoading(false);
			setIsSuccessModalOpen(true);
		}
	};

	useEffect(() => {
		let secondsLeft = countdown;

		const id = setInterval(() => {
			secondsLeft--;
			setCountdown(secondsLeft);

			if (secondsLeft <= 0) {
				setCountdown(60);
				if (!isSuccessModalOpen) {
					getRates(fromAmount);
				}

				secondsLeft = 60;
			}
		}, 1000);

		setIntervalId(id);

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [fromAmount, countdown, intervalId, getRates, isSuccessModalOpen]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title={t('pages.swap.header')} />
				<AccountDetailsSubHeader />
				<InstructionBubble title={t('pages.swap.note.title')} text={t('pages.swap.note.message')} />

				<AccountBalance userWallets={userWallets} />
			</div>
			<div>
				<div className="flex flex-col text-center mt-[20px]">
					<h1 className="text-lg font-bolder mb-[10px]">{t('pages.swap.title')}</h1>

					<div className="relative ">
						<div
							className="absolute right-[50%] top-[3rem] z-10"
							onClick={() => {
								setFromCurrency(toCurrency);
								setToCurrency(fromCurrency);
								setFromAmount(0);
								setToAmount(0);
							}}
						>
							<img src="/assets/icons/input_swap_currencies.svg" className="" alt="swap_currencies" />
						</div>
					</div>

					<div className="relative mb-[10px]">
						<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">{t('pages.swap.label.from')}</p>
						<input
							name="fromAmount"
							value={fromAmount}
							className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
							placeholder="0.00"
							type="number"
							maxLength={10}
							onChange={(e) => {
								const value = e.target.value;
								if (value.length <= 10) {
									setFromAmount(value);
									getRates(value);
								}
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="absolute right-[0.6rem] top-[0.45rem]">
							<ReactSelect
								value={{
									value: fromCurrency,
									label: (
										<div className="flex items-center">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">$</div>
											{fromCurrency}
										</div>
									),
								}}
								onChange={async (value) => {
									setFromCurrency(value.value);
									setToAmount(0);
									setFromAmount(0);
								}}
								options={fromCurrencies.map((opt) => ({
									value: opt,
									label: (
										<div className="flex">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">
												{opt === 'USD' ? '$' : '€'}
											</div>
											{opt}
										</div>
									),
								}))}
								styles={customStyles}
							/>
						</div>
					</div>

					<div className="relative">
						<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">{t('pages.swap.label.to')}</p>
						<input
							disabled={true}
							name="toAmount"
							value={toAmount}
							className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
							placeholder="0.00"
							type="text"
							maxLength={10}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="absolute right-[0.6rem] top-[0.45rem]">
							<ReactSelect
								value={{
									value: toCurrency,
									label: (
										<div className="flex items-center">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">€</div>
											{toCurrency}
										</div>
									),
								}}
								onChange={(value) => {
									setToCurrency(value.value);
									setToAmount(0);
									setFromAmount(0);
								}}
								options={toCurrencies.map((opt) => ({
									value: opt,
									label: (
										<div className="flex">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">
												{opt === 'USD' ? '$' : '€'}
											</div>
											{opt}
										</div>
									),
								}))}
								styles={customStyles}
							/>
						</div>
					</div>
				</div>

				<p className="text-gradient font-bold mt-5" dir={langDir}>
					{t('pages.swap.rates.first')} {countdown} {t('pages.swap.rates.second')}
				</p>
				<div className="flex justify-center p-5">
					<PageLogo className="!h-[100px]" />
				</div>
			</div>
			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text={t('buttons.swap')} textColor="text-white" iconPath="icons/button_swap_currencies.svg" onClick={swap} className="mb-5" />
			</div>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title={t('modals.swap.title')}
				subTitle={t('modals.swap.subTitle')}
				children={
					<div>
						<p className="text-xxl text-center mb-5">
							<div className="flex justify-center items-center">
								<p className="text-red">
									{fromCurrency === 'USD' ? '$' : '€'} {numeral(fromAmount).format('0,0.00')}
								</p>
								<img src="/assets/icons/exchange_horizontal.svg" className="w-[20px] me-5 ms-5" alt="exchange_icon" />
								<p className="text-green">
									{toCurrency === 'USD' ? '$' : '€'} {numeral(toAmount).format('0,0.00')}
								</p>
							</div>
						</p>
						<PrimaryButton
							text={t('buttons.close')}
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
