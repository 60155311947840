import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationHE from './locales/he/translation.json';

// The translations
const resources = {
	en: {
		translation: translationEN,
	},
	he: {
		translation: translationHE,
	},
};

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: savedLanguage, // default language
		fallbackLng: 'en', // if the translation is not available, fall back to this language
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
