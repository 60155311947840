import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';
import { CardItem } from '../../components/cards/cardItem';
import { BottomMenu } from '../../components/navigation/bottomMenu';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { getUserTransactionsAPI } from '../../services/user';
import Loader from '../utils/loader';

export default function CardTransactions() {
	const navigate = useNavigate();

	const location = useLocation();

	const { card } = location.state || {}; // type can be IBAN or CARD

	const [isLoading, setIsLoading] = useState(false);

	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		const getCardTransactions = async () => {
			setIsLoading(true);

			const transactions = await getUserTransactionsAPI('card', card._id);
			if (transactions.isSuccess) setTransactions(transactions.data);

			setIsLoading(false);
		};

		getCardTransactions();
	}, [card]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 bg-lightGreen pb-[100px]">
			<img src="/assets/icons/left_arrow_white.svg" alt="left_arrow" className="w-[24px] relative z-10" onClick={() => navigate(-1)} />

			<div className="mb-[60%]">
				<img
					src="/assets/dashboard/main_header_logo.svg"
					className="absolute top-0 left-0 w-full h-auto object-cover z-0"
					alt="Header background logo"
				/>
			</div>
			<div className="relative z-10">
				<AccountDetailsSubHeader />
			</div>
			<CardItem card={card} showActions={false} />

			<TransactionsContainer transactions={transactions} />

			<BottomMenu selected="cards" />
		</div>
	);
}
