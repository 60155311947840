import { Header } from '../../components/navigation/header';

const CardTermsAndConditions = () => {
	return (
		<div className="p-5">
			<Header title="LifeUp Terms & Conditions" hasIcon={true} hasBack={false} />
			{/* <div className="text-start mb-10">
				<h1 className="font-bolder text-xl mt-[40px] mb-[20px] text-gradient">LifeUp Terms & Conditions</h1>
				<p>
					# LifeUp International OU Terms and Conditions
					<br />
					<br />
					## LifeUp International OU Hamelacha 45, Building Elrod-Dan, Entrance 3, Floor 4, Office 241, Netanya, Israel
					<br />
					<br />
					Entered with a polish national payment institute partner into the Register of Entrepreneurs of the National Court Register kept by the
					District Court in Gliwice, 10th Commercial Division of the National Court Register under KRS number 0000350151, NIP: 5213540295, REGON:
					142004870, share capital PLN 721,250 paid in full.
					<br />
					<br />
					**TERMS OF SERVICE**
					<br />
					<br />
					**Version No.: 1.2** **Valid from: 01/02/2024**
					<br />
					<br />
					## I. GENERAL INFORMATION
					<br />
					<br />
					1. The LifeUp Terms of Service define the conditions under which LifeUp provides services to Individual and Business Customers in the scope
					of: - Opening and maintaining payment accounts ("Accounts"). - Issuing and servicing payment cards ("Cards"). - Execution of Transactions. -
					Providing access to payment services via a mobile application ("Digital Bank By Paytora Application") or an online platform ("Customer
					Panel"). - Other services as listed on the Paytora Portal.
					<br />
					2. LifeUp's Terms of Service are integral to the Framework Agreement.
					<br />
					3. The current Terms are available for download, saving, and printing via the Paytora System and the Paytora Portal.
					<br />
					4. Customers must read the Terms of Service before concluding the Framework Agreement.
					<br />
					5. LifeUp is authorized to provide payment services as a National Payment Institution (NPI), as referred to in art. 60 s. 1 UUP. The scope
					of services covered by the permit can be found at: [KNF](https://www.knf.gov.pl/podmioty/wyszukiwarka_podmiotow#2448926)
					<br />
					<br />
					## II. DEFINITIONS USED IN THE TERMS OF SERVICE
					<br />
					<br />
					- **Acquirer**: A bank or entity that has an agreement with the Merchant to accept payment using payment cards.
					<br />
					- **Merchant**: An entrepreneur with an agreement with the Acquirer to accept payments using payment cards.
					<br />
					- **AML**: Act of 2 March 2018 on counteracting money laundering and terrorist financing.
					<br />
					- **NFC proximity antenna**: An electronic device in a mobile device using NFC technology to communicate with a proximity reader for
					Proximity Transactions.
					<br />
					- **API**: LifeUp service access programming system.
					<br />
					- **Digital Bank By Paytora Application**: An application installed on mobile devices that provides electronic banking services, including
					payment services, via Paytora. It is required to use the Account and Card.
					<br />
					- **Authorisation**: The Customer's consent to execute a Transaction.
					<br />
					- **ATM**: A device allowing cash withdrawals using Cards.
					<br />- **Chargeback**: Procedure initiated by LifeUp to recover funds from a disputed Card Transaction based on a Customer complaint.
					<br />
					- **CVV2/CVC2**: A three-digit security number for Card verification.
					<br />
					- **Billing Cycle**: A monthly period after which a Statement is generated.
					<br />
					- **Proximity Reader**: An electronic device used to conduct Proximity Transactions, reading Card data via NFC.
					<br />
					- **Value Date**: The date from/to which LifeUp calculates interest on the debited Account.
					<br />
					- **Biometric Data**: Data enabling unambiguous identification of the Customer, e.g., fingerprint, face scan.
					<br />
					- **Payee's Supplier**: Entity providing payment services to the Payee.
					<br />
					- **Available Funds**: The balance of funds in the Account available for use up to established Transaction Limits.
					<br />
					- **Business Day**: Every day from Monday to Friday, excluding public holidays in Poland.
					<br />
					- **Days Off**: Public holidays and Saturdays.
					<br />
					- **Disposition**: A request by the Customer during the Framework Agreement term to issue a Card.
					<br />
					- **IBAN**: International Bank Account Number used to identify bank accounts in transactions.
					<br />
					- **Helpline**: A 24/7 service for cancellation and blocking of Cards, with other service hours available on the Paytora Portal.
					<br />
					- **Card**: A payment instrument issued to the Account by LifeUp, allowing cash withdrawals or payments in various forms (physical,
					virtual).
					<br />
					- **LifeUp Card**: A physical payment card issued based on a Card Agreement or Disposition.
					<br />
					- **Proximity Card**: A card with a proximity antenna for NFC technology transactions.
					<br />
					- **Virtual Card**: A virtual payment instrument issued by LifeUp.
					<br />
					- **Customer**: Individual Customer or Business Customer.
					<br />
					- **Business Customer**: A natural person, legal entity, or organizational unit with legal capacity, submitting an Application and
					concluding Agreements with LifeUp in connection with business activities.
					<br />
					- **Individual Customer**: A natural person submitting an Application and concluding Agreements with LifeUp as a consumer.
					<br />
					- **3D Secure Code**: A one-time code sent to verify Customer identity and authorize Distance Transactions.
					<br />
					- **Transaction Limit**: The maximum limit set for Transactions within a specified period.
					<br />
					- **Payee**: The recipient of cash funds in a Transaction.
					<br />
					- **Operation**: Any Non-Cash or Cash Transaction, debits, and credits on the Account, including fees and commissions.
					<br />
					- **PAN (Primary Account Number)**: A sixteen-digit number identifying the card.
					<br />
					- **Customer Panel**: An electronic banking service through which LifeUp provides services.
					<br />
					- **PIN**: A four-digit confidential code for identifying the Customer and authorizing Transactions.
					<br />
					- **Account**: An individual payment account maintained by LifeUp’s digital banking partner Paytora Payment LTD.
					<br />
					- **Card Account**: An account used to settle Transactions and Card Payments.
					<br />
					- **Regulations**: Terms of Service, Table of Fees and Commissions, Card Agreement, and other related agreements.
					<br />
					- **LifeUp Terms of Service**: This document.
					<br />
					- **Regulation (EU) 2015/751**: Regulation on interchange fees for card-based payment transactions.
					<br />
					- **Partner's Website**: An application enabling access to LifeUp services through the Paytora System.
					<br />
					- **NFC technology**: Wireless technology for contactless data transmission.
					<br />
					- **POS Terminal**: Device for Cash and Cashless Transactions, reading Card data.
					<br />
					- **Transaction**: Deposit, transfer, or withdrawal of money initiated by the Customer or Payee.
					<br />
					- **Cashless Transaction**: Card Transaction for goods or services payments.
					<br />
					- **Cash Transaction**: Card transaction constituting a cash payment.
					<br />
					- **Remote Transaction**: Cashless Transaction that does not require physical Card presentation.
					<br />
					- **Foreign Transaction**: Transaction made in a foreign currency or with a Merchant outside Poland.
					<br />
					- **Proximity Transaction**: Transaction using NFC Technology.
					<br />
					<br />
					## III. CONCLUSION OF A FRAMEWORK AGREEMENT
					<br />
					<br />
					**CARDS**
					<br />
					<br />
					### §1 Virtual Card
					<br />
					<br />
					**Issue and Activation of the Virtual Card**
					<br />
					<br />
					1. LifeUp issues a Virtual Card to the Customer based on:
					<br />
					- The Card Agreement under the Framework Agreement.
					<br />
					- Disposition submitted during the term of the Framework Agreement.
					<br />
					2. A prerequisite for issuing a Virtual Card is that the Customer holds an Account and access to the Digital Bank By Paytora Application or
					the Customer Panel.
					<br />
					3. The Virtual Card is issued as an electronic record in the Digital Bank By Paytora Application or Customer Panel.
					<br />
					4. The Virtual Card is active immediately after issue and does not require additional activation.
					<br />
					5. The Customer may assign a PIN to the Virtual Card for ATM cash withdrawals using an NFC interface.
					<br />
					6. The Virtual Card can be used for contactless payments using the NFC module on compatible mobile devices.
					<br />
					<br />
					**Using the Virtual Card**
					<br />
					<br />
					7. The Virtual Card requires prior crediting of the Account with the desired amount.
					<br />
					8. Crediting the Account can be done multiple times during the term of the Card Agreement.
					<br />
					9. Transactions are executed up to the amount of Available Funds and the Transaction Limit.
					<br />
					10. Information on the Transaction Limit is available in the LifeUp System.
					<br />
					11. Balance and Transaction history are accessible through the Digital Bank By Paytora Application or Customer Panel.
					<br />
					12. The Virtual Card allows:
					<br />
					- Non-Cash Transactions via phone and online.
					<br />
					-Contactless payments using paired mobile devices.
					<br />
					- Payments using QR codes.
					<br />
					- Money transfers in the MoneySend service.
					<br />- Cash withdrawals from contactless ATMs.
					<br />
					13. The Virtual Card does not allow:
					<br />
					- Cash withdrawals from non-contactless ATMs.
					<br />
					- Cash deposits in a cash machine.
					<br />
					14. LifeUp reserves the right to expand Virtual Card functions.
					<br />
					15. The Customer can cancel the Virtual Card anytime via the LifeUp system or Helpline.
					<br />
					16. The Virtual Card's validity period is indicated in the Digital Bank By Paytora Application or Customer Panel.
					<br />
					17. After expiry, the Virtual Card will no longer be active.
					<br />
					<br />
					### §2 LifeUp Card
					<br />
					<br />
					**Issue and Activation of the LifeUp Card**
					<br />
					<br />
					1. LifeUp issues a LifeUp Card to the Customer based on:
					<br />
					- The Card Agreement under the Framework Agreement.
					<br />
					- Disposition submitted during the term of the Framework Agreement.
					<br />
					2. A prerequisite for issuing a LifeUp Card is holding an Account.
					<br />
					3. Multiple LifeUp Cards may be issued to one Account.
					<br />
					4. The LifeUp Card is a physical Proximity Card.
					<br />
					5. LifeUp sends the LifeUp Card to the Customer's correspondence address.
					<br />
					6. The LifeUp Card requires activation via the LifeUp System.
					<br />
					7. If not received, the Customer should contact LifeUp, and LifeUp will issue a new LifeUp Card if necessary.
					<br />
					8. The Customer should activate and assign a PIN to the LifeUp Card upon receipt.
					<br />
					<br />
					**Using the LifeUp Card**
					<br />
					<br />
					9. The LifeUp card allows:
					<br />
					- Non-Cash Transactions via phone and online.
					<br />
					- Contactless payments using paired mobile devices.
					<br />
					- Payments using QR codes.
					<br />
					- Money transfers in the MoneySend service.
					<br />
					- Cash withdrawals from ATMs.
					<br />
					10. The LifeUp card does not allow:
					<br />
					- Cash deposits in a cash machine.
					<br />
					11. Balance and Transaction history are accessible via the LifeUp System.
					<br />
					12. Information on the Transaction Limit is available in the LifeUp System.
					<br />
					13. The Customer can cancel the LifeUp Card anytime via the LifeUp system or Helpline.
					<br />
					14. The validity period is shown on the LifeUp System and the Card.
					<br />
					15. The Customer must sign the LifeUp Card on the reverse.
					<br />
					<br />
					### §4 Cancelling and Blocking the Card
					<br />
					<br />
					1. LifeUp cancels the Card if:
					<br />
					- The Customer withdraws from the Framework Agreement or Card Agreement.
					<br />
					- The Customer terminates the Framework Agreement or Card Agreement.
					<br />
					- The Framework Agreement or Card Agreement expires or is terminated.
					<br />
					- The Customer requests Card cancellation.
					<br />
					2. LifeUp may cancel or block the Card if:
					<br />
					- The Card is used contrary to the Terms of Service.
					<br />
					- There is a security concern or suspicion of unauthorized use.
					<br />
					3. LifeUp prioritizes blocking the Card unless cancellation is required by law or for security.
					<br />
					4. The Customer must apply to cancel the Card if it is lost, stolen, or used unauthorized.
					<br />
					5. LifeUp blocks the Card after three incorrect PIN entries, unlockable via the LifeUp System or Helpline.
					<br />
					6. LifeUp notifies the Customer of Card cancellation or blocking via the LifeUp System.
					<br />
					7. A cancelled Card cannot be reused.
					<br />
					<br />
					### §5 Safety of Using the Card
					<br />
					<br />
					1. The Card is for Customer use only.
					<br />
					2. The Customer must:
					<br />
					- Use the Card as per the Terms of Service.
					<br />
					- Not disclose Card details unnecessarily.
					<br />
					- Monitor Account transactions for inconsistencies.
					<br />
					- Destroy any media storing the PIN upon Card cancellation.
					<br />
					<br />
					## IX. PRINCIPLES OF LIABILITY
					<br />
					<br />
					**Responsibility of the Customer**
					<br />
					<br />
					1. The Customer must protect the Card against unauthorized access.
					<br />
					2. The Customer is fully responsible for Transactions made with the Card during the term of the Agreement.
					<br />
					3. Unauthorized use liability is limited to EUR 50 unless due to intentional or grossly negligent breach by the Customer.
					<br />
					4. The Customer is not liable for unauthorized Transactions if LifeUp does not use strong authentication unless intentional.
					<br />
					<br />
					**LifeUp's Responsibility**
					<br />
					<br />
					1. LifeUp is liable for non-execution or improper execution of Transactions unless due to:
					<br />
					- IBAN errors.
					<br />
					- Force majeure.
					<br />
					- Other legal provisions.
					<br />
					2. In such cases, LifeUp restores the Account to its pre-Transaction state.
					<br />
					3. LifeUp assists in tracing non-performed or improperly performed Transactions.
					<br />
					<br />
					## X. TRANSACTIONS ### §1 General Rules
					<br />
					<br />
					1. LifeUp performs various Card and Account Transactions based on the Customer's Payment Order.
					<br />
					2. Fees and commissions are specified in the Table of Fees and Commissions.
					<br />
					3. Customers must cover fees, commissions, and debts promptly.
					<br />
					4. LifeUp may set and adjust maximum daily Transaction Limits.
					<br />
					<br />
					### §2 Card Transactions
					<br />
					<br />
					**Authorisation of Card Transactions**
					<br />
					<br />
					1. Transaction authorization methods include:
					<br />
					- PIN at POS Terminal.
					<br />
					- NFC interface for contactless payments.
					<br />
					- PIN at ATMs.
					<br />
					- Card data for Distance Transactions.
					<br />
					2. Transactions under PLN 100 may not require PIN.
					<br />
					3. LifeUp uses strong authentication for Transactions.
					<br />
					<br />
					**3D-Secure Service**
					<br />
					<br />
					1. Distance Transactions may require a 3D-Secure Code.
					<br />
					2. 3D-Secure Codes are sent via push message or SMS.
					<br />
					<br />
					**Card Transaction Currency**
					<br />
					<br />
					1. Transactions are in the Card's currency.
					<br />
					2. Foreign Transactions are converted at Mastercard/Visa rates.
					<br />
					<br />
					### §3 Transactions with Use of the Account
					<br />
					<br />
					1. Transactions are executed based on the Customer's Payment Order.
					<br />
					2. Payment Orders include transfer details and references.
					<br />
					<br />
					## XI. STATEMENT OF OPERATIONS
					<br />
					<br />
					1. Monthly Statements of Operations are available in the LifeUp System.
					<br />
					<br />
					2. Statements can be requested via mail for a fee.
					<br />
					3. Customers must report discrepancies within 14 days of receiving statements.
					<br />
					<br />
					## XII. COMPLAINTS
					<br />
					<br />
					1. Complaints must include Customer details and description of the issue.
					<br />
					2. Complaints can be submitted electronically, via mail, or Helpline.
					<br />
					3. LifeUp responds to complaints within 15 Business Days or 35 in complex cases.
					<br />
					4. Complaints about unauthorized Transactions must be made within 13 months of the Transaction date.
					<br />
					<br />
					## XIII. REFUND OF THE AUTHORISED AMOUNT
					<br />
					<br />
					1. Refund requests must be made within 8 weeks of the Transaction.
					<br />
					2. Refunds are subject to conditions like undetermined amounts at the time of Authorization.
					<br />
					3. LifeUp responds to refund requests within 10 Business Days.
					<br />
					<br />
					## XIV. TERMS AND CONDITIONS OF TERMINATION OF AGREEMENTS ###
					<br />
					<br />
					§1 Withdrawal from the Framework Agreement
					<br />
					<br />
					1. Customers can withdraw from the Framework Agreement within 14 days of conclusion.
					<br />
					2. Withdrawal voids the Agreement and incurs no costs except for services performed before withdrawal. ### §2 Termination by the Customer
					<br />
					<br />
					1. Customers can terminate the Framework Agreement with a 1-month notice.
					<br />
					2. Notice period starts from the date of notice receipt by LifeUp.
					<br />
					<br />
					### §3 Termination by LifeUp
					<br />
					<br />
					1. LifeUp can terminate the Framework Agreement with a 2-month notice for reasons like unauthorized use or inactivity.
					<br />
					2. Customers must settle any debts before the end of the notice period.
					<br />
					<br />
					## XV. MODE FOR CHANGE OF REGULATIONS
					<br />
					<br />
					1. LifeUp can change the Table of Fees and Commissions or Terms of Service for reasons like new regulations or technological advances.
					<br />
					2. Customers are notified 2 months before changes take effect and can terminate the Agreement without fees if they object.
					<br />
					<br />
					## XVI. FINAL PROVISIONS
					<br />
					<br />
					**Communication**
					<br />
					<br />
					1. LifeUp contacts Customers via provided addresses, emails, and phone numbers.
					<br />
					2. Customers must notify LifeUp of any changes in contact details or provided information.
					<br /> 3. Communication methods include Helpline, email, and mail.
					<br />
					<br />
					**Disputes**
					<br />
					<br />
					1. Disputes are settled by the court competent for LifeUp’s registered office.
					<br />
					2. Polish law applies to matters not covered by the Terms of Service.
					<br />
					Table of Card Fees are found in <a href="https://www.paytorabank.com/card-fees">https://www.paytorabank.com/card-fees</a>
				</p>
			</div> */}
			<div className="terms-container px-6 py-4">
				{/* <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1> */}
				<h1 className="font-bolder text-xl mb-[20px] text-gradient">LifeUp Terms & Conditions</h1>
				<p className="mb-4">
					Effective Date: <strong>1.11.2024</strong>
				</p>
				<p className="mb-4">
					Welcome to Lifeup International OÜ. Please read these Terms and Conditions carefully as they outline the legal obligations, rights, and
					responsibilities for using our services, including Mastercard-branded debit cards provided in partnership with an authorized Mastercard
					issuer.
				</p>
				<h2 className="text-xl font-bold mt-6 mb-2">1. Definitions</h2>
				<ul className="list-disc ml-6 mb-4">
					<li>
						<strong>Company:</strong> Refers to Lifeup International OÜ.
					</li>
					<li>
						<strong>Issuer:</strong> The licensed Mastercard issuing bank that partners with the Company.
					</li>
					<li>
						<strong>Card:</strong> Mastercard-branded debit card issued under the Lifeup International program.
					</li>
					<li>
						<strong>Cardholder:</strong> An individual who has been issued a Card by the Issuer under the Company’s program.
					</li>
					<li>
						<strong>Account:</strong> The account linked to the Card used for transactions and funding.
					</li>
					<li>
						<strong>Merchant:</strong> Any business or entity that accepts Mastercard payments.
					</li>
				</ul>

				<h2 className="text-xl font-bold mt-6 mb-2">2. Services Provided</h2>
				<p className="mb-4">
					Lifeup International OÜ, in partnership with the Issuer, offers Mastercard debit cards to its customers. The Company manages the card
					program, handles branding, customer support, and marketing, while the Issuer oversees compliance, regulatory obligations, and transaction
					processing.
				</p>
				<h3 className="font-bold mb-2">Key Features:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>Virtual and physical Mastercard debit cards.</li>
					<li>Card usage for online and in-person payments.</li>
					<li>Access to mobile app for card management, including balance checks, transaction history, and security features.</li>
				</ul>

				<h2 className="text-xl font-bold mt-6 mb-2">3. Account and Card Eligibility</h2>
				<p className="mb-4">To be eligible for a Mastercard debit card under the Lifeup International program, you must:</p>
				<ul className="list-disc ml-6 mb-4">
					<li>Be at least 18 years old.</li>
					<li>Provide valid identification and verification documents.</li>
					<li>Agree to the terms of the Cardholder Agreement provided by the Issuer.</li>
				</ul>
				<p className="mb-4">The Issuer reserves the right to approve or deny card applications based on their compliance policies.</p>

				<h2 className="text-xl font-bold mt-6 mb-2">4. Fees and Charges</h2>
				<p className="mb-4">
					By using the Card, you agree to the applicable fees as outlined in the Fees and Charges Schedule, including but not limited to:
				</p>
				<ul className="list-disc ml-6 mb-4">
					<li>Card issuance fee</li>
					<li>Monthly maintenance fee</li>
					<li>ATM withdrawal fees</li>
					<li>Foreign transaction fees</li>
					<li>Inactivity fees</li>
				</ul>
				<p className="mb-4">These fees are subject to change, and you will be notified of any changes at least 30 days in advance.</p>

				<h2 className="text-xl font-bold mt-6 mb-2">5. Card Usage</h2>
				<p className="mb-4">The Card may be used for:</p>
				<ul className="list-disc ml-6 mb-4">
					<li>Purchases at any merchant that accepts Mastercard.</li>
					<li>Online payments and transactions.</li>
					<li>Cash withdrawals at ATMs displaying the Mastercard logo.</li>
				</ul>
				<p className="mb-4">
					The Cardholder must ensure sufficient funds are available in the linked account before making transactions. Overdrafts are not permitted,
					and any attempt to exceed the available balance may result in a declined transaction.
				</p>

				<h2 className="text-xl font-bold mt-6 mb-2">6. Liability and Limitations</h2>
				<ul className="list-disc ml-6 mb-4">
					<li>
						Lifeup International OÜ is not liable for any losses arising from unauthorized transactions if the Cardholder fails to safeguard their
						Card or account credentials.
					</li>
					<li>The Cardholder must notify customer support immediately if their Card is lost, stolen, or used without authorization.</li>
					<li>
						The Company and Issuer shall not be held liable for indirect or consequential damages, including lost profits or reputational harm, except
						in cases of fraud or willful misconduct.
					</li>
				</ul>

				<h2 className="text-xl font-bold mt-6 mb-2">7. Dispute Resolution</h2>
				<p className="mb-4">
					For any disputes related to transactions or services, please contact our customer support team. If the issue cannot be resolved internally,
					you may escalate the dispute to the relevant financial authority or arbitration body.
				</p>
				<h3 className="font-bold mb-2">Arbitration Clause:</h3>
				<p className="mb-4">
					Any unresolved disputes shall be settled through arbitration under the jurisdiction of the Estonian Arbitration Court. The decision of the
					arbitrator shall be final and binding.
				</p>

				<h2 className="text-xl font-bold mt-6 mb-2">8. Confidentiality and Data Protection</h2>
				<p className="mb-4">
					Lifeup International OÜ and the Issuer are committed to protecting your personal data. We comply with the General Data Protection Regulation
					(GDPR) and will only use your data for the purposes of providing the services described in this agreement.
				</p>
				<h3 className="font-bold mb-2">Data Usage:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>Your data will be used to facilitate transactions, verify identity, and comply with legal requirements.</li>
					<li>We will not share your personal information with third parties without your explicit consent, except as required by law.</li>
				</ul>

				<h2 className="text-xl font-bold mt-6 mb-2">9. Amendments to Terms and Conditions</h2>
				<p className="mb-4">
					Lifeup International OÜ reserves the right to amend these Terms and Conditions at any time. Changes will be communicated to Cardholders via
					email or in-app notifications at least 30 days prior to their effective date. Continued use of the Card after changes have been made
					constitutes acceptance of the revised Terms and Conditions.
				</p>

				<h2 className="text-xl font-bold mt-6 mb-2">10. Termination</h2>
				<p className="mb-4">
					The Cardholder may terminate this agreement by contacting customer support and closing their account. Lifeup International OÜ or the Issuer
					may terminate the Cardholder’s account and cancel the Card at any time with 30 days’ notice or immediately if there is evidence of
					fraudulent activity, misuse, or violation of these Terms and Conditions.
				</p>

				<h2 className="text-xl font-bold mt-6 mb-2">11. Governing Law</h2>
				<p className="mb-4">
					These Terms and Conditions are governed by and construed in accordance with the laws of Estonia. Any legal actions or proceedings arising
					out of this agreement shall be exclusively conducted in the courts of Tallinn, Estonia.
				</p>

				<h2 className="text-xl font-bold mt-6 mb-2">12. Contact Information</h2>
				<ul className="list-disc ml-6 mb-4">
					<li>
						<strong>Email:</strong> support@lifeupinternational.com
					</li>
					<li>
						<strong>Phone:</strong> +44 2037690016
					</li>
					<li>
						<strong>Address:</strong> Harju maakond, Tallinn, Kesklinna linnaosa, Narva mnt 5, 10117
					</li>
				</ul>
			</div>
		</div>
	);
};

export default CardTermsAndConditions;
