import { z } from 'zod';

export const loginSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('errors.validations.email') }),
		password: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.password') }),
	});

export const registerConfirmSchema = (t) =>
	z
		.object({
			otp: z
				.string()
				.trim()
				.min(4, { message: t('errors.validations.otp') }),
			password: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.password') })
				.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/, {
					message: t('passwordInstructions.message'),
				}),
			confirmPassword: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.confirmPassword') }),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('errors.validations.passwordsNotMatch'),
		});

export const registerSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('errors.validations.email') }),
		type: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.type') }),
	});
