import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';
import { useTranslation } from 'react-i18next';

export default function ResultPage({ isSuccess = false }) {
	const navigate = useNavigate();

	const { t } = useTranslation();

	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			navigate('/');
		}, 3000); // 3 seconds

		return () => {
			clearTimeout(redirectTimer);
		};
	}, [navigate]);

	return (
		<div className="p-5">
			<Header hasIcon={true} hasBack={false} showUserChat={false} showLanguage={false} />
			<div className="flex justify-center mt-20 mb-20">
				<PageLogo />
			</div>
			<div className="flex justify-center items-center">
				{isSuccess ? (
					<div className="flex flex-col items-center">
						<img src="/assets/icons/check.svg" className="w-[30%]" alt="check_icon" />
						<h1 className="text-lg font-bolder mt-5 mb-2">{t('pages.result.succuess.title')}</h1>
						<p className="text-md">{t('pages.result.subTitle')}</p>
					</div>
				) : (
					<div className="flex flex-col items-center">
						<img src="/assets/icons/x_sign.svg" className="w-[30%]" alt="x_icon" />
						<h1 className="text-lg font-bolder mt-5 mb-2">{t('pages.result.failed.title')}</h1>
						<p className="text-md">{t('pages.result.subTitle')}</p>
					</div>
				)}
			</div>
		</div>
	);
}
