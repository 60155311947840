import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Median from 'median-js-bridge';

import useValidation from '../../hooks/useValidation';

import Loader from '../utils/loader';

import { Input } from '../../components/inputs/textInput';
import { PrimaryButton } from '../../components/buttons/primary-button';
import PasswordInput from '../../components/inputs/passwordInput';

import { login } from '../../services/auth';

import { useTranslation } from 'react-i18next';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { loginSchema } from '../../utils/validations/auth/auth_schema';
import { PageLogo } from '../../components/utils/pageLogo';

export default function Login() {
	const navigate = useNavigate();

	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const { validationErrMsg, validate } = useValidation(loginSchema(t));

	const [apiErrorMsg, setApiErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [hasTouchId, setHasTouchId] = useState(false);

	const [isNative, setIsNative] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		const isValid = validate({ email: username, password });
		if (!isValid) return setApiErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await login(username, password);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`errors.server.${response.error}`));
			} else {
				if (hasTouchId && isNative) {
					const secret = JSON.stringify({
						username: username,
						password: password,
					});

					await Median.auth.save({
						secret: secret,
						// minimumAndroidBiometric: 'strong',
					});
				}
				setApiErrorMsg('');
				navigate('/');
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setIsNative(Median.isNativeApp());

		const medianCheckBio = async () => {
			await Median.auth.status().then((data) => {
				if (isNative && data) {
					setHasTouchId(data.hasTouchId);
				}
			});
		};

		medianCheckBio();
	}, [isNative]);

	return isLoading ? (
		<Loader />
	) : (
		<form onSubmit={handleSubmit} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} />

				<h1 dir={langDir} className="text-xl font-bolder mb-5">
					{t('pages.login.title')}
				</h1>
				<Input
					onChange={(value) => {
						setUsername(value);
					}}
					placeholder={t('inputs.placeholders.email')}
					type="text"
					value={username}
				/>
				<PasswordInput value={password} onChange={setPassword} placeholder={t('inputs.placeholders.password')} />

				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrorMsg && <ErrorBubble error={apiErrorMsg} />}
				<Link to="/forgot-password" className="text-lg text-gradient font-bolder flex justify-end">
					{t('pages.login.forgotPassword')}
				</Link>
			</div>
			<PageLogo />
			<div>
				<PrimaryButton text={t('buttons.login')} className="mb-[10px]" />
				<div className="flex items-center" dir={langDir}>
					<img src="/assets/icons/info.svg" className="me-[12px]" alt="info" />
					<p className="text-sm">
						{t('pages.login.signup.text')}
						<Link to="/register" className="text-gradient font-bold ms-2">
							{t('pages.login.signup.link')}
						</Link>
					</p>
				</div>
			</div>
		</form>
	);
}
