import { useTranslation } from 'react-i18next';

export default function Loader() {
	const { t } = useTranslation();

	return (
		<div className={`h-screen flex flex-col justify-center items-center bg-transparent`}>
			<h1 className="tex-xl font-bolder mb-[10px]">{t('pages.loader.title')}</h1>
			<p className="text-md text-darkGray mb-[50px]">{t('pages.loader.subTitle')}</p>
			<div className="relative flex items-center justify-center">
				<div className="gradient-spinner w-[100px] h-[100px] rounded-full"></div>
			</div>
		</div>
	);
}
