import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../../utils/loader';
import { getVerestroIbanAPI } from '../../../services/ibans';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/accountDetailsSubHeader';
import { InstructionBubble } from '../../../components/utils/InstructionBubble';
import { CustomModal } from '../../../components/modals/customModal';
import { PrimaryButton } from '../../../components/buttons/primary-button';

export default function WireTransfer() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const location = useLocation();
	const { isCard = false, cardId } = location.state || {}; // type can be IBAN or CARD

	const [isLoading, setIsLoading] = useState(false);

	const [ibanData, setIbanData] = useState(null);

	const [showContriesListModal, setShowCountriesListModal] = useState(false);

	const getVerestroIBANDetails = async () => {
		// add error when not agreed
		if (!isLoading) {
			setIsLoading(true);
			const response = await getVerestroIbanAPI(cardId);
			if (!response.isSuccess) return setIsLoading(false);

			setIbanData(response.data);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getVerestroIBANDetails();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			{ibanData ? (
				<div>
					<Header title={isCard ? t('pages.ibanDetails.card.header') : t('pages.ibanDetails.iban.header')} />
					<AccountDetailsSubHeader />

					<InstructionBubble
						title={t('pages.ibanDetails.instructions.title')}
						text={
							<div>
								<h1 className="text-md text-center font-bold mb-1">{t('pages.ibanDetails.instructions.text')}</h1>
								<ul className="text-start">
									<li>{t('pages.ibanDetails.instructions.option1')}</li>
									<li>{t('pages.ibanDetails.instructions.option2')}</li>
									<li>{t('pages.ibanDetails.instructions.option3')}</li>
								</ul>
							</div>
						}
					/>
					<p className="text-center text-lg mt-2">
						{t('pages.ibanDetails.label.restricted.country')}
						<PrimaryButton text={t('buttons.viewCountries')} className="font-bolder ms-1" onClick={() => setShowCountriesListModal(true)} />
					</p>
					<div>
						<h1 className="text-lg font-bolder mt-[40px]">
							{t('pages.ibanDetails.details.title.first')} {isCard && t('pages.ibanDetails.details.title.card')}{' '}
							{t('pages.ibanDetails.details.title.second')}
						</h1>
						<p className="text-sm text-darkGray mt-[20px]">{t('pages.ibanDetails.details.holderName')}</p>
						<p className="mb-[20px]">{ibanData ? ibanData.fullName : ''}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.iban')}</p>
						<p className="mb-[20px]">{ibanData ? ibanData.iban : ''}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.bic')}</p>
						<p className="mb-[20px]">{ibanData ? ibanData.bic : ''}</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.bankName')}</p>
						<p className="mb-[20px]">Bank Polska Kasa Opieki Spolka Akcyjna</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.address')}</p>
						<p className="mb-[20px]">al. Wojska Polskiego 1</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.city')}</p>
						<p className="mb-[20px]">Szczecin</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.postalCode')}</p>
						<p className="mb-[20px]">70-470</p>
						<p className="text-sm text-darkGray">{t('pages.ibanDetails.details.country')}</p>
						<p className="mb-[20px]">Poland (PL)</p>
					</div>
				</div>
			) : (
				<div className="p-5">
					<Header title={isCard ? t('pages.ibanDetails.card.header') : t('pages.ibanDetails.iban.header')} />

					<h1 className="text-xl text-center font-bold">{t('pages.ibanDetails.label.noIban')}</h1>
				</div>
			)}

			<CustomModal
				isOpen={showContriesListModal}
				onClose={() => setShowCountriesListModal(false)}
				title={t('modals.ibanDetails.title')}
				subTitle={t('modals.ibanDetails.subTitle')}
				children={
					<div>
						<p className="text-red font-bold text-center">{t('modals.ibanDetails.message')}</p>
						<ul className="p-2 pb-5 font-bold" dir="ltr">
							<li>Afghanistan</li>
							<li>Barbados</li>
							<li>Burkina Faso</li>
							<li>Democratic Republic of the Congo</li>
							<li>Philippines</li>
							<li>Gibraltar</li>
							<li>Haiti</li>
							<li>Iran</li>
							<li>Jamaica</li>
							<li>Yemen</li>
							<li>Cameroon</li>
							<li>Democratic People's Republic of Korea</li>
							<li>Mali</li>
							<li>Myanmar/Burma</li>
							<li>Mozambique</li>
							<li>Nigeria</li>
							<li>Panama</li>
							<li>South Africa</li>
							<li>Senegal</li>
							<li>South Sudan</li>
							<li>Syria</li>
							<li>Tanzania</li>
							<li>Trinidad and Tobago</li>
							<li>Uganda</li>
							<li>Vanuatu</li>
							<li>Vietnam</li>
							<li>United Arab Emirates</li>
						</ul>
						<div className="text-center">
							<PrimaryButton onClick={async () => setShowCountriesListModal(false)} text={t('buttons.close')} textColor="text-white" />
						</div>
					</div>
				}
			/>
		</div>
	);
}
