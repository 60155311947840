export const SecondaryButton = ({ onClick, text, disabled, className, textColor = 'text-white' }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'bg-darkGray- first-letter:' : 'bg-transparent'
			} active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-[6px] ${textColor} text-lg max-w-md  border border-white ${className} `}
		>
			{text}
		</button>
	);
};
