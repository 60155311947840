import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { Input } from './textInput';
import { useState } from 'react';

export default function PasswordInput({ value, onChange, placeholder = 'Password' }) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="relative w-full max-w-md">
			<Input
				placeholder={placeholder}
				value={value}
				type={showPassword ? 'text' : 'password'}
				onChange={(value) => {
					onChange(value);
				}}
			/>
			<div className="absolute inset-y-0 right-0 pr-3 pb-4 flex items-center cursor-pointer mt-2">
				{showPassword ? <HiOutlineEyeOff onClick={() => setShowPassword(false)} /> : <HiOutlineEye onClick={() => setShowPassword(true)} />}
			</div>
		</div>
	);
}
