import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

export default function CardAndIbanTransactionItem({ transaction }) {
	const { t } = useTranslation();

	return (
		<div>
			<div className="p-5 mb-[10px]">
				<div className="flex justify-between mb-[10px]">
					<h1 className="text-lg text-gradient">{t(`pages.transactions.item.types.${transaction.type}`)}</h1>
					<p className="text-sm">
						{t('pages.transactions.item.status')} -{' '}
						<span className={`${transaction.status === 'completed' ? 'text-green' : 'text-orange'}`}>
							{t(`pages.transactions.item.statuses.${transaction.status}`)}
						</span>
					</p>
				</div>
				<div className="flex justify-between">
					<div className="flex">
						<img src="/assets/transactions/transfer.svg" className="me-[10px]" />
						<div className="flex flex-col text-xsm justify-center">
							{transaction.fromCardLastDigits && transaction.type !== 'card_to_card_receive' && (
								<p className="flex">
									<img src="/assets/icons/half_arrow_red.svg" className="me-1" /> {t(`pages.transactions.item.card`)}:{' '}
									{transaction.fromCardLastDigits}
								</p>
							)}

							{transaction.toCardLastDigits && (
								<p className="flex">
									<img src="/assets/icons/half_arrow_green.svg" className="me-1" /> {t(`pages.transactions.item.card`)}:{' '}
									{transaction.toCardLastDigits}
								</p>
							)}
							{transaction.description && <p>{transaction.description}</p>}
							{transaction.paymentMethod && (
								<p>
									{t(`pages.transactions.item.method`)}: {transaction.paymentMethod}
								</p>
							)}
						</div>
					</div>
					<div>
						<p
							className={`text-lg ${
								transaction.type === 'deposit' ||
								transaction.type === 'card_to_card_receive' ||
								(transaction.type === 'purchase' && transaction.status === 'reversal') ||
								transaction.type === 'incoming_wire'
									? 'text-green'
									: 'text-red'
							}`}
						>
							{transaction.currencySymbol}
							{numeral(transaction.amount).format('0,0.00')}
						</p>
					</div>
				</div>
				<div className="text-sm flex justify-between mt-2">
					<p>{transaction.createdAt}</p>
					<h1 className="text-gradient text-end">{transaction.kind}</h1>
				</div>
			</div>
		</div>
	);
}
