export const PrimaryButton = ({ onClick, text, disabled = false, className, iconPath, textColor = 'text-gradient' }) => {
	return (
		<button
			type="submit"
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'bg-darkGray- first-letter:' : 'bg-black'
			} active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out h-[48px] w-full rounded-[6px] text-lg max-w-md ${className}`}
		>
			<div className="flex justify-center">
				{iconPath ? <img src={`/assets/${iconPath}`} className="me-2" /> : ''}

				<p className={textColor}>{text}</p>
			</div>
		</button>
	);
};
