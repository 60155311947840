import { apiRequest } from './api';

export const getUserAccountsAPI = async () => {
	try {
		const response = await apiRequest('/user/accounts', 'GET', null, true);

		return { isSuccess: true, data: response.data, totalBalance: response.totalBalance };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
